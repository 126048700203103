import React from 'react';
import { Row, Form, Spinner, Col, Button, Container } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Dispatch } from '../../components/dispatcher';
import { NavLink } from 'react-router-dom';

export const ProfilePage = () => {

    const [profileImage, setProfileImage] = useState(null);
    const [userDetails, setUserDetails] = useState(null);

    let inputChangeTimeout = -1;

    const formRef = useRef();
    const { addToast } = useToasts();

    useEffect(() => {
        reload();
    }, []);

    const reload = () => {
        Dispatch("api/user/getuserdetails").then(r => {
            setUserDetails(r);
        });
    };

    const save = () => {
        let form = formRef.current;

        Dispatch("api/user/SaveUserDetails", "POST", {
            userName: form["name"].value
        }, false).then(() => {
            addToast('Saved changes!', { appearance: 'success' });
            reload();
        });
    };

    const onFormInputChanged = (e) => {
        clearTimeout(inputChangeTimeout);
        inputChangeTimeout = setTimeout(() => {
            save();
        }, 2000);
    };

    const onProfileImageSelected = (e) => {
        let input = e.target;

        if (input.files.length != 1) return;
        setProfileImage(window.URL.createObjectURL(input.files[0]));

        let formData = new FormData();
        formData.append("profile-picture", input.files[0]);

        fetch("api/user/UploadProfilePicture", {
            method: 'POST',
            body: formData
        }).then(r => {
            addToast('Uploaded new profile picture!', { appearance: 'success' });
            window.location.href = window.location.href;
        });
    };

    if (userDetails == null) return <Spinner animation="grow" />

    return <Container>
        <Form ref={formRef}>
            <Row>
                <Col xs="auto pr-0">
                    <NavLink to="/">
                        <Button variant="light"><span className="icon-arrow-left" /></Button>
                    </NavLink>
                </Col>
                <Col>
                    <h3>YOUR PROFILE</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p className="scanbie-title">Summary</p>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Form.Group controlId="icon-file" required className="text-center">
                        <Form.Label>
                            Upload a profile picture
                        </Form.Label>
                        <img className="profile-image" style={{ display: "block", margin: 'auto', width: 150, height: 150, objectFit: "cover" }} src={profileImage || `/api/user/GetProfilePicture`} />
                        <Form.Control type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e) => onProfileImageSelected(e)} />
                    </Form.Group>
                </Col>
                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>Your email</Form.Label>
                        <Form.Control type="text" defaultValue={userDetails.email} disabled={true} />
                        <Form.Text>Cannot be changed</Form.Text>
                    </Form.Group>
                </Col>
                <Col xs={12}>
                    <Form.Group controlId="name" required>
                        <Form.Label>Your name</Form.Label>
                        <Form.Control type="text" defaultValue={userDetails.name} onChange={(e) => onFormInputChanged(e)} />
                    </Form.Group>
                </Col>
                <Col xs={12}>
                    <NavLink to="/de" style={{ color: 'red' }} />
                </Col>
                <Col xs={"auto"} className="mt-3">
                    <NavLink className="w-100" to="/request-recover-password">Change password</NavLink>
                </Col>
            </Row>
        </Form>
    </Container >
};
import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button } from "@material-ui/core";
import { ArrowDownShort } from "react-bootstrap-icons";
import { ProjectTile } from './project-tile';
import { Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import { CreateProjectTile } from './create-project-tile';
import { useHistory } from 'react-router-dom';


export const ProjectAccordion = (props) => {

    const [expanded, setExpanded] = useState(true);
    const history = useHistory();

    const filteredProjectIds = props.projects.filter(p => p.name.toLowerCase().indexOf(props.search.toLowerCase()) >= 0).map(p => p.id);

    if (filteredProjectIds.length == 0 && !props.canCreateProjects) return null;

    return <Accordion expanded={expanded} className="mb-3">
        <AccordionSummary
            expandIcon={<ArrowDownShort />}
            className="scanbie-title"
            onClick={() => setExpanded(!expanded)}
        >
            <Typography>{props.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            {props.projects.length == 0 && <div className="w-100 justify-content-center align-items-center">
                <Row className="justify-content-center justify-content-sm-start  pt-4" style={{ overflowY: 'hidden' }}>
                    <Col className="text-center">
                        <img width={150} className="drone-animated-search my-5" src="/images/drone.png" alt="drone" />
                        <h4>No projects available!</h4>
                        {props.canCreateProjects && <Button variant="contained" color="primary" className="mt-2" onClick={() => history.push('/create-project')}><span className="icon-webpack" /> Create your first project!</Button>}
                    </Col>
                </Row>
            </div>}
            {props.projects.length != 0 && <div className="w-100">
                <Row className="justify-content-center justify-content-sm-start pt-2" style={{ overflowY: 'hidden' }}>
                    {props.projects.map((p, i) => <Col key={i} xs="auto" className="mb-2" hidden={filteredProjectIds.indexOf(p.id) < 0}>
                        <div>
                            <ProjectTile value={p} />
                        </div>
                    </Col>)}
                    {props.canCreateProjects && <Col xs="auto" className="mb-3">
                        <CreateProjectTile onClick={() => history.push("/create-project")} />
                    </Col>}
                </Row>
            </div>}
        </AccordionDetails>
    </Accordion>;
};
import React, { useEffect } from "react";
import { Tooltip, OverlayTrigger, Badge, Button, Col, Row } from "react-bootstrap";
import { Plus, Dash, Trash, PlusCircle, TrashFill, PlusCircleFill, EyeSlash, Eye, Pencil } from "react-bootstrap-icons";
import { Card, CardContent, CardHeader, CardActions, CardMedia, Paper } from '@material-ui/core';
import { useState } from "react";


export const PublicUserModule = (props) => {

    const [publicUserModuleRoleId, setPublicUserModuleRoleId] = useState(0);

    useEffect(() => {
        setPublicUserModuleRoleId(props.publicUserModuleRoleId);
    }, [props.publicUserModuleRoleId]);

    const getModuleRoleText = () => {
        if (publicUserModuleRoleId == 3) return "Cannot use";
        if (publicUserModuleRoleId == 2) return "Can view";
        if (publicUserModuleRoleId == 1) return "Can edit";
    };

    const onChange = (selectedRoleId) => {
        setPublicUserModuleRoleId(selectedRoleId);
        props.onChange(selectedRoleId);
    };

    return <Card className="card-project-user-module">
        <CardMedia image="images/cube.svg"></CardMedia>
        <CardHeader title={props.name}>
        </CardHeader>
        <CardContent>
            {getModuleRoleText()}
        </CardContent>
        <CardActions disableSpacing>
            <div className="module-roles ">
                <Paper>
                    <Row className="flex-column">
                        <Col xs="auto">
                            <Button variant={publicUserModuleRoleId == 3 ? 'primary' : 'outlined'} onClick={() => onChange(3)}>
                                <EyeSlash />
                            </ Button>
                        </Col>
                        <Col xs="auto">
                            <Button variant={publicUserModuleRoleId == 2 ? 'primary' : 'outlined'} onClick={() => onChange(2)}>
                                <Eye />
                            </ Button>
                        </Col>
                        {/*<Col xs="auto">
                            <Button variant={publicUserModuleRoleId == 1 ? 'primary' : 'outlined'} onClick={() => onChange(1)}>
                                <Pencil />
                            </ Button>
                        </Col>*/}
                    </Row>
                </Paper>
            </div>
        </CardActions>
    </Card>;
};
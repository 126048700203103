import React from 'react';
import { ListGroupItem, Col, Tooltip } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Envelope } from 'react-bootstrap-icons';
import { OverlayTrigger } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';
import { Dispatch } from '../dispatcher';
import { useToasts } from 'react-toast-notifications';
import { useModal } from '../../components/layout/modal';
import { IconButton } from '@material-ui/core';

export const InvitationItem = (props) => {

    let invitation = props.value;

    const { addToast } = useToasts();
    const { showYesNoModal } = useModal();

    const resendEmail = () => {
        showYesNoModal("Send email", "Are you sure you want to resend an invitation email?", () => {
            Dispatch(`/api/projects/ResendEmail/${invitation.id}`, "POST").then(r => {
                let isError = r && !!r.error;
                addToast(isError ? r.error : 'Mail sent!', { appearance: isError ? 'error' : 'info' });
            });
        });
    };

    const deleteInvitation = () => {
        showYesNoModal("Delete user", "Are you sure you want to delete this user?", () => {
            Dispatch(`api/Projects/DeleteProjectUserInvitation/${invitation.id}`, 'DELETE').then(r => {
                addToast("Project user deleted!", { appearance: 'success' });
                props.onReload();
            });
        });
    };

    return <ListGroupItem className="collaborator-list-item d-flex flex-row justify-content-between align-items-center mb-1">
        <div className="d-flex flex-row">
            <Row className="ml-1 justify-content-start">
                <Col xs="auto" className="px-0">
                    <img width={50} height={50} className="collaborator-icon" style={{ backgroundImage: `url("/images/astro-64.png")` }} />
                </Col>
                <Col className="pr-0" style={{ overflow: 'hidden' }}>
                    <p style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}><b>{invitation.email}</b></p>
                    <Badge variant="info">Invitation sent</Badge>
                </Col>
            </Row>
        </div>
        <div>
            <OverlayTrigger overlay={<Tooltip>Resend invitation email</Tooltip>}>
                <IconButton variant="outlined" onClick={() => resendEmail()}>
                    <span className="icon-email" />
                </IconButton>
            </OverlayTrigger>
            <IconButton variant="outlined" onClick={() => deleteInvitation()}>
                <span className="icon-delete" />
            </IconButton>
        </div>
    </ListGroupItem>;
};
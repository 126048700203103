import React, { useEffect } from 'react';
import { ListGroupItem, Col, Modal } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import { Dispatch } from '../dispatcher';
import { useModal } from '../layout/modal';
import { useLayout } from '../layout';
import { IconButton, Checkbox, FormControlLabel } from '@material-ui/core';
import { ProjectUserRoles } from '../../constants';
import { useState } from 'react';
import { ProjectUserModule } from '../module/project-user-module';
import { useConfig } from '../../App';

export const CollaboratorItem = (props) => {

    const [editCollaboratorData, setEditCollaboratorData] = useState(null);

    const collaborator = props.value;

    const { addToast } = useToasts();
    const { showYesNoModal } = useModal();
    const { getUserProfile } = useConfig();

    const userProfile = getUserProfile();

    const deleteCollaborator = () => {
        showYesNoModal("Warning!", "Are you sure you want to delete this invitation?", () => {
            Dispatch(`api/Projects/DeleteProjectUser/${collaborator.id}`, 'DELETE').then(r => {
                addToast("Project user deleted!", { appearance: 'success' });
                props.onReload();
            });
        });
    };

    const loadCollaboratorData = () => {
        Dispatch(`api/projects/getcollaboratordata/${collaborator.id}`).then(r => {
            setEditCollaboratorData(r);
        });
    };

    const setProjectUserModuleRole = (moduleId, roleId) => {
        Dispatch(`api/projects/setProjectUserModuleRole/${editCollaboratorData.id}/${moduleId}/${roleId}`, 'POST').then(r => {
            addToast("Module role changed!", { appearance: 'success' });
            loadCollaboratorData();
        });

    };

    const setProjectUserRole = (roleId) => {
        Dispatch(`api/projects/SetProjectUserRole/${editCollaboratorData.id}/${roleId}`, 'POST').then(r => {
            addToast("Project user role changed!", { appearance: 'success' });
            loadCollaboratorData();
        });
    };

    return <ListGroupItem className="collaborator-list-item d-flex flex-row justify-content-between align-items-center mb-1">
        <div className="d-flex flex-row">
            <img width={50} height={50} className="collaborator-icon" style={{ backgroundImage: `url("/api/user/GetProfilePicture/${collaborator.user.id}"), url("/images/astro-64.png")` }} />
            <Row className="ml-1">
                <Col xs={12} style={{ overflow: 'hidden' }}>
                    <p><b>{collaborator.user.name}</b> {collaborator.user.id == userProfile.userId ? ' (You)' : ''}</p>
                </Col>
                <Col xs={12}>
                    {collaborator.roleId == ProjectUserRoles.Owner && <p>Owner</p>}
                    {collaborator.roleId == ProjectUserRoles.Administrator && <p>Administrator</p>}
                    {collaborator.roleId == ProjectUserRoles.Client && <p>Client</p>}
                </Col>
            </Row>
        </div>
        <div>
            {collaborator.roleId >= ProjectUserRoles.Administrator && collaborator.user.id != userProfile.userId && <IconButton onClick={() => loadCollaboratorData()}>
                <span className="icon-settings" />
            </IconButton>}
            {collaborator.roleId != ProjectUserRoles.Owner && collaborator.user.id != userProfile.userId && <IconButton onClick={() => deleteCollaborator()}>
                <span className="icon-delete" />
            </IconButton>}
        </div>
        {editCollaboratorData && <Modal show={!!editCollaboratorData} onHide={() => { setEditCollaboratorData(null); props.onReload();}} size="lg">
            <Modal.Header closeButton>
                Edit client {collaborator.user.name}
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox color="primary" type="checkbox" defaultChecked={editCollaboratorData.projectRoleId == ProjectUserRoles.Administrator} onChange={(e) => setProjectUserRole(e.target.checked ? ProjectUserRoles.Administrator : ProjectUserRoles.Client)} />
                            }
                            label="Project Administrator"
                        />
                    </Col>
                </Row>
                <Row>
                    {editCollaboratorData.projectUserModuleRoles.map((m, i) => <Col key={i} xs="auto" className="my-2">
                        <ProjectUserModule isAdmin={editCollaboratorData.projectRoleId == ProjectUserRoles.Administrator} roleId={m.roleId} name={m.moduleName} onChange={roleId => setProjectUserModuleRole(m.moduleId, roleId)}/>
                    </Col>)}
                </Row>
            </Modal.Body>
        </Modal>}
    </ListGroupItem>;
};
import React from 'react';
import { Container, Form } from 'react-bootstrap';
import { Dispatch } from '../../components/dispatcher';
import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

import '../login/login-page.scss';
import { NavLink, useHistory } from 'react-router-dom';
import { useModal } from '../../components/layout/modal';

export const RequestRecoverPasswordPage = () => {

    const [errorMessage, setErrorMessage] = useState('');
    const { showInfoModal } = useModal();
    const history = useHistory();

    const requestRecoverPassword = (event) => {
        event.preventDefault();

        let form = event.currentTarget;

        Dispatch(`api/user/RequestRecoverPassword`, "POST", { email: form["email"].value }).then(r => {
            showInfoModal("Password recovery has been sent!", "An email should arrive within a few minutes. Glad to have you back on board!", () => {
                history.push("/login");
            });
        });
    };

    const goToScanbieWebsite = () => {
        window.open("https://www.scanbie.com", "_blank");
    };

    return <Container className="full-page">
        <Row className="justify-content-center mt-5">
            <Col xs={12} md={7} lg={5}>
                <Form onSubmit={e => requestRecoverPassword(e)} className="login-form d-flex flex-column align-items-center justify-content-center py-4">
                    <img src="/images/logo.png" width="55%" alt="logo" />
                    <Form.Group>
                        {errorMessage && <Alert variant="danger">
                            {errorMessage}
                        </Alert>}
                    </Form.Group>
                    <Form.Group className="text-center" controlId="email" required>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="text" placeholder="Enter email" />
                    </Form.Group>
                    <Button type="submit"><span className="icon-rotate-3d" /> Recover password</Button>
                    <NavLink className="mt-2" to="/login"> Back to log in</NavLink>
                </Form>
            </Col>
        </Row>
        <Row className="justify-content-center mt-2">
            <Col xs="auto"><NavLink to="">Help</NavLink></Col>
            <Col xs="auto"><NavLink to="">Privacy Policy</NavLink></Col>
            <Col xs="auto"><NavLink to="">Terms of service</NavLink></Col>
        </Row>
        <Row className="mt-2 justify-content-center">
            <Col lg={4}>
                <img onClick={() => goToScanbieWebsite()} className="img-fluid my-3" style={{ cursor: "pointer" }
                } alt="scanbie-www" src="/images/www-scanbie-com.png" />
            </Col>
        </Row>
        <img className="astro" alt="astro" src="/images/astro-body.png" />
    </Container>;
};
import React from 'react';
import { Form, Spinner, Button, Collapse, Modal } from 'react-bootstrap';
import { useEffect } from 'react';
import { useState } from 'react';
import { Dispatch } from '../../../components/dispatcher';
import { useModal } from '../../../components/layout/modal';

export const InviteUser = (props) => {

    const [modules, setModules] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const { showInfoModal } = useModal();

    useEffect(() => {
        Dispatch("api/user/getusermodules").then(r => setModules(r));
        setIsAdmin(false);
    }, [props.show]);

    const onSubmit = (e) => {
        e.preventDefault();

        let form = e.currentTarget;
        let body = {
            email: form["email"].value,
            isAdmin: form["is-admin"].checked,
            moduleIds: modules.filter(m => form["module-" + m.id.toString()].checked).map(m => m.id)
        };

        Dispatch("api/user/inviteuser", "POST", body).then(r => {
            props.onHide();
            showInfoModal("Success", "An invitation email has been send to the user!");
        }).catch(e => {
            props.onHide();
            showInfoModal("Error", e);
        });
    };

    if (isLoading) return <Spinner animation="grow" />;

    return <Modal show={props.show} size="lg" onHide={() => props.onHide(false)}>
        <Form onSubmit={e => onSubmit(e)}>
            <Modal.Header closeButton>
                <h3>Invite user</h3>
            </Modal.Header>
            <Modal.Body className="p-4">
                <p>Users can create projects and invite other people to their own projects.</p>
                <Form.Group controlId="email" required>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" />
                </Form.Group>
                <Form.Group controlId="is-admin">
                    <Form.Check type="checkbox" label="Is Scanbie Admin" onChange={e => setIsAdmin(e.target.checked)} />
                </Form.Group>
                {isAdmin && <p>Scanbie admins can use all modules</p>}
                <Collapse in={!isAdmin}>
                    <div>
                        <h4>Modules the user has access to</h4>
                        <p>When this user creates a project, he will be able to add the following modules to his project.</p>
                        {modules.map(m => <Form.Group key={m.id} controlId={"module-" + m.id.toString()}>
                            <Form.Check type="checkbox" label={m.name} />
                        </Form.Group>
                        )}
                    </div>
                </Collapse>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit"><span className="icon-email" /> Invite</Button>
            </Modal.Footer>
        </Form>
    </Modal>
};
import React from 'react';
import { Container, Form } from 'react-bootstrap';
import { Dispatch } from '../../components/dispatcher';
import { Button } from 'react-bootstrap';

import './login-page.scss';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';
import { useModal } from '../../components/layout/modal';
import { useConfig } from '../../App';

export const LoginPage = () => {

    const { showInfoModal } = useModal();
    const { reloadUserProfile } = useConfig();
    const history = useHistory();

    const goToScanbieWebsite = () => {
        window.open("https://www.scanbie.com", "_blank");
    };

    const submitLogin = (event) => {
        event.preventDefault();

        let form = event.currentTarget;

        Dispatch("api/user/login", "POST", {
            email: form["email"].value,
            password: form["password"].value
        }).then(r => {
            reloadUserProfile(() => {
                history.push('/');
            });
        }).catch(e => {
            showInfoModal("Error", e);
        });
    };

    return <Container className="full-page">
        <Row className="justify-content-center mt-5">
            <Col xs={12} md={7} lg={5}>
                <Form onSubmit={e => submitLogin(e)
                } className="login-form d-flex flex-column align-items-center justify-content-center py-4">
                    <img src="/images/logo.png" width="55%" alt="logo" />
                    <Form.Group className="text-center" controlId="email" required>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="text" placeholder="Enter email" />
                    </Form.Group>
                    <Form.Group className="text-center" controlId="password" required>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" />
                    </Form.Group>
                    <Button type="submit"><span className="icon-rocket" /> Log In</Button>
                    <NavLink className="mt-3" to="/request-recover-password">Forgot password?</NavLink>
                    <NavLink className="mt-3" style={{ color: 'red' }
                    } to="/register">No account yet? Register here</NavLink>
                </Form>
            </Col>
        </Row>
        <Row className="justify-content-center mt-2">
            <Col xs="auto"><NavLink to="">Help</NavLink></Col>
            <Col xs="auto"><NavLink to="">Privacy Policy</NavLink></Col>
            <Col xs="auto"><NavLink to="">Terms of service</NavLink></Col>
        </Row>
        <Row className="mt-2 justify-content-center">
            <Col lg={4}>
                <img onClick={() => goToScanbieWebsite()} className="img-fluid my-3" style={{ cursor: "pointer" }
                } alt="scanbie-www" src="/images/www-scanbie-com.png" />
            </Col>
        </Row>
        <img className="astro" alt="astro" src="/images/astro-body.png" />
    </Container>;
};
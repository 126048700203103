export const UserRoles = {
    Admin: 1,
    User: 2,
    Public: 3
};

export const ProjectUserRoles = {
    Owner: 1,
    Administrator: 2,
    Client: 3
};

export const AssetTypes = {
    Icon: 0,
    ProjectAsset: 1,
    ModuleAsset: 5
};

export const HugeUploadAssetType = {
    LOD: 0,
    TexturePack: 1
};
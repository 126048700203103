import React from "react";
import { Tooltip, OverlayTrigger, Badge } from "react-bootstrap";
import { Plus, Dash, Trash, PlusCircle, TrashFill, PlusCircleFill } from "react-bootstrap-icons";
import { Card, CardContent, CardHeader, CardActions, CardMedia, Button } from '@material-ui/core';


export const Module = (props) => {
    return <Card className="card-module" style={{ opacity: props.isStandardModule ? 0.5 : 1 }}>
        <CardMedia image="images/cube.svg"></CardMedia>
        <CardHeader title={<>
            {props.name}
            {props.isStandardModule && <OverlayTrigger
                overlay={
                    <Tooltip>
                        Is a standard module.
                        </Tooltip>
                }
            >
                <Badge variant="primary" className="ml-1">S</Badge>
            </OverlayTrigger>}
        </>}>
        </CardHeader>
        <CardActions disableSpacing>
            {props.canAddModule && <Button fullWidth color="primary" variant="contained" onClick={() => props.onAdd()}>
                <PlusCircleFill className="mr-1" /> Add
            </Button>}
            {props.canRemoveModule && <Button fullWidth color="secondary" variant="contained" onClick={() => props.onRemove()}>
                <TrashFill className="mr-1" /> Remove
            </Button>}
        </CardActions>
    </Card>;
    /*return <Card className="card-module" style={{ opacity: props.isStandardModule ? 0.5 : 1 }}>
        <Card.Img variant="top" src="images/cube.svg" />
        <Card.Body>
            <Card.Title>
                {props.name}
                {props.isStandardModule && <OverlayTrigger
                    overlay={
                        <Tooltip>
                            Is a standard module.
                        </Tooltip>
                    }
                >
                    <Badge variant="primary" className="ml-1">S</Badge>
                </OverlayTrigger>}
            </Card.Title>
            {props.canAddModule && <Button onClick={() => props.onAdd()} variant="danger" className="add-btn"><PlusCircleFill /></Button>}
            {props.canRemoveModule && <Button onClick={() => props.onRemove()} variant="light" className="remove-btn"><TrashFill className="badge-danger"/></Button>}
        </Card.Body>
    </Card>;*/
};
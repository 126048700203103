import React from 'react';
import { Card } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

export const LoadingProjectTile = (props) => {
    return <Card className="project-tile">
        <Skeleton height={150} width={200} />
        <Card.Title className="text-center"><Skeleton count={1} /></Card.Title>
        <Card.Text className="text-center"><Skeleton count={2} /></Card.Text>
    </Card>;
};
import React from 'react';
import { Form, Spinner, Modal, Container } from 'react-bootstrap';
import { useEffect } from 'react';
import { Dispatch } from '../../components/dispatcher';
import { useParams, NavLink, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Collaborators } from '../../components/collaborators/collaborators';
import { useRef } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Globe, Phone, Display } from 'react-bootstrap-icons';
import { useLayout, setLoadingProgress, setShowLoadingProgress } from '../../components/layout';
import { useModal } from '../../components/layout/modal';
import { ProjectModules } from './components/modules/project-modules';
import { UserRoles, ProjectUserRoles } from '../../constants';
import * as TUS from 'tus-js-client';
import { Paper, AppBar, Tabs, Tab, IconButton, Checkbox, FormControlLabel } from '@material-ui/core';
import { useConfig } from '../../App';
import { ProjectInfo } from './components/info/project-info';
import { ProjectFiles } from './components/project-files/project-files';

export const ProjectSettingsPage = () => {
    const params = useParams();
    const [project, setProject] = useState(null);
    const [displayProjectName, setDisplayProjectName] = useState('');
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const { getUserProfile } = useConfig();
    const { showYesNoModal, showInfoModal } = useModal();
    const { addToast } = useToasts();

    const userProfile = getUserProfile();
    const history = useHistory();

    const reload = () => {
        Dispatch(`api/projects/details/${params.id}`).then(r => {
            setProject(r);
            setDisplayProjectName(r.name);
        });
    };

    useEffect(() => {
        reload();
    }, []);

    const deleteProject = () => {
        showYesNoModal("Warning!", "Deleting a project cannot be undone!", () => {
            Dispatch(`/api/projects/delete/${project.id}`, 'DELETE').then(r => {
                showInfoModal("Success!", "Successfully deleted project.", () => history.push('/'));
            }).catch(e => {
                addToast('Error deleting project.', { appearance: 'error' });
            });
        });
    };

    if (project == null) return <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" />
    </div>;

    const isAdmin = userProfile.role == UserRoles.Admin;
    const currentProjectUser = project.projectUsers.find(pu => pu.user.id == userProfile.userId);
    const isOwner = currentProjectUser?.roleId == ProjectUserRoles.Owner;
    const canDeleteProject = isAdmin || isOwner;

    return <Container>
        <Paper className="p-1">
            <Row className="align-items-center">
                <Col xs="auto pr-0">
                    <NavLink to="/">
                        <IconButton><span className="icon-arrow-left" /></IconButton>
                    </NavLink>
                </Col>
                <Col>
                    <h3>{displayProjectName}</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p className="scanbie-title">Summary</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Tabs
                        textColor="primary"
                        indicatorColor="primary"
                        value={selectedTabIndex}
                        variant="scrollable"
                        onChange={(e, i) => setSelectedTabIndex(i)}
                        scrollButtons="auto"
                    >
                        <Tab aria-controls="Projectinfo" label="Project info" />
                        <Tab aria-controls="Users" label="Users" />
                        <Tab hidden={!isOwner && !isAdmin} aria-controls="Modules" label="Modules" />
                        <Tab aria-controls="ProjectFiles" label="3D Model Files" />
                        <Tab hidden={!canDeleteProject} aria-controls="DeleteProject" label="Delete Project" />
                    </Tabs>
                    <div>
                        <div role="tabpanel" id="Projectinfo" hidden={selectedTabIndex != 0} className="p-3">
                            <ProjectInfo
                                isPublic={project.isPublic}
                                name={project.name}
                                addressLine={project.addressLine1}
                                city={project.city}
                                state={project.state}
                                zip={project.zip}
                                country={project.country}
                                contactName={project.contactName}
                                contactEmail={project.contactEmail}
                                contactTel={project.contactTel}
                                onFormSaved={(form) => setDisplayProjectName(form.projectName)}
                            />
                        </div>
                        <div role="tabpanel" id="Users" hidden={selectedTabIndex != 1} className="p-3">
                            <Collaborators
                                projectId={project.id}
                                onReload={() => reload()}
                                projectUsers={project.projectUsers}
                                projectUserInvitations={project.projectUserInvitations}
                            />
                        </div>
                        {(isOwner || isAdmin) && <div role="tabpanel" id="Modules" hidden={selectedTabIndex != 2} className="p-3">
                            <ProjectModules
                                projectId={project.id}
                                isPublic={project.isPublic}
                                onReload={() => reload()}
                            />
                        </div>}
                        <div role="tabpanel" id="Assetbundles" hidden={selectedTabIndex != 3} className="p-3">
                            <ProjectFiles
                                lodLevels={project.projectLodLevels}
                                textureLevels={project.projectTextureLevels}
                                onReload={() => reload()}
                            />
                        </div>
                        {canDeleteProject && <div role="tabpanel" id="DeleteProject" hidden={selectedTabIndex != 4} className="p-3">
                            <Row className="justify-content-center m-5">
                                <Button onClick={() => deleteProject()} variant="danger">Delete project</Button>
                            </Row>
                        </div>}
                    </div>
                </Col>
            </Row>
        </Paper>
    </Container>;
};
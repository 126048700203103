import React, { Component, useState, useEffect, createContext, useContext } from 'react';
import { Layout, useLayout } from './components/layout';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter, NavLink } from "react-router-dom";
import { Dispatch } from './components/dispatcher';
import { ProjectsOverviewPage } from './pages/projects-overview/projects-overview-page';
import { ProjectSettingsPage } from './pages/project-settings/project-settings-page';
import { LoginPage } from './pages/login/login-page';
import { RequestRecoverPasswordPage } from './pages/request-recover-password/request-recover-password-page';
import { RecoverPasswordPage } from './pages/recover-password/recover-password-page';
import { RegisterPage } from './pages/register/register-page';
import { CreateProjectPage } from './pages/create-project/create-project-page';
import { ProfilePage } from './pages/profile/profile-page';
import { ProjectPage } from './pages/project/project-page';
import { ManageModulesPage } from './pages/manage-modules/manage-modules-page';
import { ManageUsersPage } from './pages/manage-users/manage-users-page';

import { AdminHelpCreateModulePage } from './pages/admin-help-create-module/admin-help-create-module.page';
import { HelpCreateGLBPage } from './pages/help-create-glb/help-create-glb.page';

import { Row, Col } from 'react-bootstrap';
import SwipeableRoutes from "react-swipeable-routes";
import { ManageBuildPage } from './pages/manage-builds/manage-build-page';
import { UserRoles } from './constants';
import './unity-hooks/unzipper';
import "./unity-hooks/texturebinder";

export const useConfig = () => {
    return useContext(ConfigContext);
};

const ConfigContext = createContext({
    getUserProfile: () => { },
    reloadUserProfile: (callback) => { }
});


let reloadUserProfileCallback = null;

export const App = (props) => {

    const displayName = App.name;

    const [loaded, setLoaded] = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const [authenticated, setAuthenticated] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        reloadUserProfile();
    }, []);

    useEffect(() => {
        setAuthenticated(userProfile != null && userProfile.role < UserRoles.Public);
        setIsAdmin(userProfile != null && userProfile.role == UserRoles.Admin);
        if (reloadUserProfileCallback) reloadUserProfileCallback();
    }, [userProfile]);

    const getUserProfile = () => {
        return userProfile;
    };

    const reloadUserProfile = (callback) => {
        setLoaded(false);
        reloadUserProfileCallback = callback;
        Dispatch('api/user/getuserprofile').then(r => {
            setUserProfile(r);
            setLoaded(true);
        });
    };

    if (!loaded) {
        return <div className="scanbie-loading flex-column d-flex justify-content-center align-items-center">
            <img src="/images/logo.png" />
            <h3>Loading platform...</h3>
        </div>;
    }

    return (
        <Router>
            <ConfigContext.Provider value={{ getUserProfile, reloadUserProfile }}>
                <Layout>
                    <Switch>
                        <Route exact path="/login">
                            <LoginPage />
                        </Route>
                        <Route exact path="/request-recover-password">
                            <RequestRecoverPasswordPage />
                        </Route>
                        <Route exact path="/recover-password/:token">
                            <RecoverPasswordPage />
                        </Route>
                        <Route exact path="/project-settings/:id">
                            {authenticated ? <ProjectSettingsPage /> : <Redirect to="/" />}
                        </Route>
                        <Route exact path="/create-project">
                            {authenticated ? <CreateProjectPage /> : <Redirect to="/" />}
                        </Route>
                        <Route exact path="/register/">
                            <RegisterPage />
                        </Route>
                        <Route exact path="/profile">
                            {authenticated ? <ProfilePage /> : <Redirect to="/" />}
                        </Route>
                        <Route exact path="/project/:id">
                            <ProjectPage />
                        </Route>
                        <Route exact path="/manage-modules">
                            {isAdmin ? <ManageModulesPage /> : <Redirect to="/" />}
                        </Route>
                        <Route exact path="/manage-users">
                            {isAdmin ? <ManageUsersPage /> : <Redirect to="/" />}
                        </Route>
                        <Route exact path="/manage-builds">
                            {isAdmin ? <ManageBuildPage /> : <Redirect to="/" />}
                        </Route>
                        {/* Help pages */}
                        <Route exact path="/admin-help-create-module">
                        {isAdmin ? <AdminHelpCreateModulePage /> : <Redirect to="/" />}
                        </Route>
                        <Route exact path="/admin-help-deploy-build">
                        {isAdmin ? <AdminHelpCreateModulePage /> : <Redirect to="/" />}
                        </Route>
                        <Route exact path="/help-create-glb">
                            <HelpCreateGLBPage />
                        </Route>
                        <Route exact path="/">
                            <ProjectsOverviewPage />
                        </Route>
                        <Route>
                            <Row className="justify-content-center">
                                <Col xs="auto">
                                    <h1>Page Not found</h1>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col xs="auto">
                                    <NavLink to="/">Go home</NavLink>
                                </Col>
                            </Row>
                        </Route>
                    </Switch>
                </Layout >
            </ConfigContext.Provider>
        </Router>
    );
}

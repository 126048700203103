import React, { useState, useEffect } from "react";
import { Dispatch } from "../../../../components/dispatcher";
import {
  Row,
  Spinner,
  Col,
  Table,
  Form,
  Button,
  Card,
  Badge,
  OverlayTrigger,
} from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { useModal } from "../../../../components/layout/modal";
import {
  Plus,
  Dash,
  TrashFill,
  ExclamationCircleFill,
} from "react-bootstrap-icons";
import { Module } from "../../../../components/module/module";
import { PublicUserModule } from "../../../../components/module/public-user-module";
import { Tooltip } from "@material-ui/core";

export const ProjectModules = (props) => {
  const [modules, setModules] = useState(null);

  const { addToast } = useToasts();
  const { showYesNoModal } = useModal();

  useEffect(() => {
    reload();
  }, []);

  const reload = () => {
    Dispatch(`api/projects/GetEditProjectModulesData/${props.projectId}`).then((m) =>
      setModules(m)
    );
  };

  const onPublicModuleRoleIdChanged = (moduleId, newModuleRoleId) => {
    Dispatch(
      `api/projects/updatepublicusermoduleroleid/${props.projectId}/${moduleId}/${newModuleRoleId}`,
      "POST"
    ).then((m) => reload());
  };

  const onModuleChange = (checked, moduleId) => {
    if (checked) {
      Dispatch(
        `api/projects/addProjectModule/${props.projectId}/${moduleId}`,
        "POST"
      ).then((r) => {
        addToast("Module added!", { appearance: "success" });
        reload();
        props.onReload();
      });
    } else {
      showYesNoModal(
        "Warning",
        "Are you sure you want to remove this module from the project? All users will loose their access to this module.",
        () => {
          Dispatch(
            `api/projects/removeProjectModule/${props.projectId}/${moduleId}`,
            "POST"
          ).then((r) => {
            addToast("Module removed!", { appearance: "success" });
            reload();
            props.onReload();
          });
        }
      );
    }
  };

  const renderModule = (projectModule) => {
    let isStandardModule = projectModule.isStandardModule;
    let canAddModule = !isStandardModule && !projectModule.inProject;
    let canRemoveModule = !isStandardModule && projectModule.inProject;

    return (
      <Module
        name={projectModule.moduleName}
        isStandardModule={isStandardModule}
        canAddModule={canAddModule}
        canRemoveModule={canRemoveModule}
        onAdd={() => onModuleChange(true, projectModule.moduleId)}
        onRemove={() => onModuleChange(false, projectModule.moduleId)}
      />
    );
  };

  const renderPublicUserRightsModule = (projectModule) => {
    return (
      <PublicUserModule
        name={projectModule.moduleName}
        publicUserModuleRoleId={projectModule.publicUserModuleRoleId}
        onChange={(newRoleId) =>
          onPublicModuleRoleIdChanged(projectModule.moduleId, newRoleId)
        }
      ></PublicUserModule>
    );
  };

  if (!modules) {
    return (
      <Row>
        <Col xs="auto">
          <Spinner animation="grow" />
        </Col>
      </Row>
    );
  }

  const publicModules = modules.filter((m) => m.inProject);

  return (
    <>
      <Row className="my-2">
        <Col xs="auto">
          <h3>Modules available in my project</h3>
        </Col>
      </Row>
      <Row className="justify-content-center justify-content-md-start">
        {modules.map((m, i) => (
          <Col key={i} xs="auto my-2">
            {renderModule(m)}
          </Col>
        ))}
      </Row>
      {publicModules.length > 0 && (
        <>
          <Row className="my-2">
            <Col xs="auto">
              <h3>
                Modules available to the public
                {!props.isPublic && (
                  <Tooltip
                    className="ml-1"
                    placement="top"
                    title={
                      <p>
                        Project is private. Go to project info to make it
                        available to the public.
                      </p>
                    }
                  >
                    <ExclamationCircleFill />
                  </Tooltip>
                )}
              </h3>
            </Col>
          </Row>
          <Row className="justify-content-center justify-content-md-start">
            {publicModules.map((m, i) => (
              <Col key={i} xs="auto my-2">
                {renderPublicUserRightsModule(m)}
              </Col>
            ))}
          </Row>
        </>
      )}
    </>
  );
};

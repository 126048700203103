import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { FormControlLabel, Checkbox, TextField } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { DispatchForm, Dispatch } from '../../../../components/dispatcher';
import { useToasts } from 'react-toast-notifications';

export const ProjectInfo = (props) => {

    const [selectedIcon, setSelectedIcon] = useState(null);

    const params = useParams();
    const formRef = useRef();
    const { addToast } = useToasts();

    let inputChangeTimeout = -1;

    const onIconSelected = (e) => {
        let input = e.target;

        if (input.files.length != 1) return;

        setSelectedIcon(window.URL.createObjectURL(input.files[0]));

        let formData = new FormData();
        formData.append("asset", input.files[0]);
        formData.append("projectId", params.id);
        formData.append("assetType", 0);

        DispatchForm("api/file/upload", formData).then(r => {
            addToast('Uploaded new icon!', { appearance: 'success' });
        }).catch(e => {
            addToast("Error uploading icon!", { appearance: 'error' });
        });
    };

    const onFormInputChanged = (e) => {
        clearTimeout(inputChangeTimeout);
        inputChangeTimeout = setTimeout(() => {
            save();
        }, 1500);
    };

    const save = () => {
        const form = formRef.current;

        const body = {
            projectName: form['project-name'].value,
            publicVisible: form['public-visible'].checked,
            addressLine1: form['address-line-1'].value,
            city: form['city'].value,
            state: form['state'].value,
            zip: form['zip'].value,
            country: form['country'].value,
            contactName: form['contact-name'].value,
            contactEmail: form['contact-email'].value,
            contactTel: form['contact-telephone'].value
        };

        Dispatch(`/api/projects/edit/${params.id}`, 'POST', body, false).then(r => {
            addToast('Changes saved', { appearance: 'success' });
            props.onFormSaved(body);
        }).catch(e => {
            addToast(e, { appearance: 'error' });
        });
    };

    return <Form ref={formRef}>
        <Form.Group controlId="icon-file" required className="text-center">
            <img style={{ display: "block", margin: 'auto', width: 150, height: 150, objectFit: "cover" }} src={selectedIcon || `api/Projects/Icon/${params.id}`} />
            <Form.Control className="mt-2" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e) => onIconSelected(e)} />
        </Form.Group>
        <Form.Group>
            <FormControlLabel
                control={
                    <Checkbox id="public-visible" color="primary" type="checkbox" defaultChecked={props.isPublic} onChange={(e) => onFormInputChanged(e)} />
                }
                label="Public visible"
            />
        </Form.Group>
        <Form.Group controlId="project-name" required>
            <Form.Label>Project Name</Form.Label>
            <Form.Control type="text" placeholder="Project Name" defaultValue={props.name} onChange={(e) => onFormInputChanged(e)} minLength="3" maxLength="50" helperText="Lenth must be between 3 and 50 characters."/>
        </Form.Group>

        <h4>Contact</h4>

        <Form.Group controlId="address-line-1" required>
            <Form.Label>Address line 1</Form.Label>
            <Form.Control type="text" placeholder="Address" defaultValue={props.addressLine} onChange={(e) => onFormInputChanged(e)}  maxLength="150"/>
        </Form.Group>
        <Row>
            <Col md={12} lg={6}>
                <Form.Group controlId="city" required>
                    <Form.Label>City</Form.Label>
                    <Form.Control type="text" placeholder="City" defaultValue={props.city} onChange={(e) => onFormInputChanged(e)} maxLength="150"/>
                </Form.Group>
            </Col>
            <Col md={12} lg={6}>
                <Form.Group controlId="state" required>
                    <Form.Label>State / Province</Form.Label>
                    <Form.Control type="text" placeholder="State" defaultValue={props.state} onChange={(e) => onFormInputChanged(e)} maxLength="150"/>
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col md={12} lg={6}>
                <Form.Group controlId="zip" required>
                    <Form.Label>Zip</Form.Label>
                    <Form.Control type="text" placeholder="Zip" defaultValue={props.zip} onChange={(e) => onFormInputChanged(e)} maxLength="4"/>
                </Form.Group>
            </Col>
            <Col md={12} lg={6}>
                <Form.Group controlId="country" required>
                    <Form.Label>Country</Form.Label>
                    <Form.Control type="text" placeholder="Country" defaultValue={props.country} onChange={(e) => onFormInputChanged(e)} />
                </Form.Group>
            </Col>
        </Row>
        <Form.Group controlId="contact-name" required>
            <Form.Label>Contact Name</Form.Label>
            <Form.Control type="text" placeholder="Name" defaultValue={props.contactName} onChange={(e) => onFormInputChanged(e)} />
        </Form.Group>
        <Form.Group controlId="contact-email" required>
            <Form.Label>Contact Email</Form.Label>
            <Form.Control type="text" placeholder="email" defaultValue={props.contactEmail} onChange={(e) => onFormInputChanged(e)} />
        </Form.Group>
        <Form.Group controlId="contact-telephone" required>
            <Form.Label>Contact Tel.</Form.Label>
            <Form.Control type="tel" placeholder="Tel" defaultValue={props.contactTel} onChange={(e) => onFormInputChanged(e)} />
        </Form.Group>
    </Form>;
};
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, FormControl } from "react-bootstrap";
import { Dispatch } from '../../components/dispatcher';
import { LoadingProjectTile } from './components/loading-project-tile';
import JSZip from "jszip";

import './projects-overview-page.scss'
import { NavLink, useHistory } from 'react-router-dom';
import { UserRoles, ProjectUserRoles } from '../../constants';
import { ProjectAccordion } from './components/projects-accordion';
import { useConfig } from '../../App';

export const ProjectsOverviewPage = () => {

    const [projects, setProjects] = useState(null);
    const [search, setSearch] = useState('');

    const { getUserProfile } = useConfig();
    const history = useHistory();

    useEffect(() => {
        Dispatch("api/projects/getprojects", "GET", null, false).then(r => {
            setProjects(r);
        });
    }, []);

    const renderProjects = () => {
        let userProfile = getUserProfile();
        let canCreateProjects = userProfile && userProfile.canCreateProjects;
        let isAdmin = userProfile != null && userProfile.role == UserRoles.Admin;

        if (projects != null) {

            if (projects.length == 0) {
                return <div className="w-100 justify-content-center align-items-center">
                    <Row className="justify-content-center justify-content-sm-start  pt-4" style={{ overflowY: 'hidden' }}>
                        <Col className="text-center">
                            <img width={150} className="drone-animated-search my-5" src="/images/drone.png" alt="drone" />
                            <h4>No projects available. Come back later.</h4>
                        </Col>
                    </Row>
                </div>;
            }

            if (search && projects.filter(p => p.name.toLowerCase().indexOf(search.toLowerCase()) >= 0).length <= 0) {
                return <div className="w-100 justify-content-center align-items-center">
                   <Row className="justify-content-center justify-content-sm-start  pt-4" style={{ overflowY: 'hidden' }}>
                       <Col className="text-center">
                           <img width={150} className="drone-animated-search my-5" src="/images/drone.png" alt="drone" />
                           <h4>No search results.</h4>
                       </Col>
                   </Row>
               </div>;
            }

            let myProjects = userProfile == null ? [] : projects.filter(p => p.projectUsers.some(pu => pu.user.id == userProfile.userId));
            let publicProjects = projects.filter(p => p.isPublic);
            let allOtherProjectsBecauseImAdmin = projects.filter(p => myProjects.indexOf(p) < 0);

            let hasMyProjectsToShow = userProfile && userProfile.role != UserRoles.Public && (myProjects.length > 0 || canCreateProjects);
            let hasPublicProjectsToShow = publicProjects.length > 0;
            let hasAllOtherProjectsBecauseImAdmin = isAdmin && allOtherProjectsBecauseImAdmin.length > 0;

            return <>
                {hasMyProjectsToShow && <ProjectAccordion title="My projects" projects={myProjects} search={search} canCreateProjects={canCreateProjects} />}
                {hasPublicProjectsToShow && <ProjectAccordion title="Public projects" projects={publicProjects} search={search} />}
                {hasAllOtherProjectsBecauseImAdmin && <ProjectAccordion title="Projects by other users" projects={allOtherProjectsBecauseImAdmin} search={search} />}
            </>;
        }

        return <Row className="justify-content-center justify-content-sm-start  pt-4" style={{ overflowY: 'hidden' }}>
            {[1, 2, 3, 4].map(i => <Col xs="auto" key={i} className="mb-3">
                <LoadingProjectTile />
            </Col>)}
        </Row>;
    };

    return <Container>
        <Row className="mb-2">
            <Col>
                <h3>PROJECTS</h3>
            </Col>
            <Col>
                <FormControl variant="outlined" placeholder="Search" onChange={e => setSearch(e.target.value)} />
            </Col>
        </Row>
        <Row>
            <Col>
                {renderProjects()}
            </Col>
        </Row>
    </Container >
};
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { CollaboratorItem } from './collaborator-item';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { FormControl } from 'react-bootstrap';
import { useState } from 'react';
import { Plus } from 'react-bootstrap-icons';
import { CollaboratorsInvite } from '../collaborators-invite/collaborators-invite';
import { InvitationItem } from './invitation-item';
import { Button } from '@material-ui/core';

export const Collaborators = (props) => {

    const [filter, setFilter] = useState('');
    const [showCollaboratorsInviteModal, setShowCollaboratorsInviteModal] = useState(false);

    let collaborators = props.projectUsers || [];
    let invitations = props.projectUserInvitations || [];

    const onCollaboratorsInvite = () => {
        setShowCollaboratorsInviteModal(false);
        props.onReload();
    };

    return <div>
        <Row className="mb-2">
            <Col>
                <FormControl placeholder="Search Users" onChange={(e) => setFilter(e.target.value)} />
            </Col>
        </Row>
        {collaborators.length == 0 && <p>No clients found.</p>}
        {collaborators.length > 0 && <Row className="mb-2">
            <Col>
                <ListGroup variant="flush">
                    {collaborators.filter(c => c.user.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0).map((c, i) => <CollaboratorItem key={i} onReload={() => props.onReload()} value={c} />)}
                    {invitations.filter(i => i.email.toLowerCase().indexOf(filter.toLowerCase()) >= 0).map((c, i) => <InvitationItem key={i} onReload={() => props.onReload()} value={c} />)}
                </ListGroup>
            </Col>
        </Row>}
        <Row>
            <Col xs={12}>
                <Button color="primary" variant="contained" onClick={() => setShowCollaboratorsInviteModal(true)} className="w-100">
                    <Plus /> Invite more users to project
                </Button>
            </Col>
        </Row>
        
        <CollaboratorsInvite projectId={props.projectId} show={showCollaboratorsInviteModal} onHide={() => onCollaboratorsInvite()}/>
    </div>;
};
import React from 'react';
import { Paper, Button, Checkbox, IconButton, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { UserRoles } from '../../../../constants';
import { Col, Row } from 'react-bootstrap';
import { Box } from 'react-bootstrap-icons';

export const UserInvitationComponent = (props) => {
    const userData = props.userData;
    const search = props.search;

    return <TableRow hidden={!(!search || userData.name?.toLowerCase().indexOf(search.toLowerCase()) >= 0)} selected>
        <TableCell>
            {userData.id}
        </TableCell>
        <TableCell>
            <Row className="align-items-center justify-content-start">
                <Col xs="auto">
                    <div style={{ backgroundImage: `url("/images/astro-64.png")` }} className="profile-image" />
                </Col>
                <Col xs="auto">
                    Pending invitation
                </Col>
            </Row>
        </TableCell>
        <TableCell>
            {userData.email}
        </TableCell>
        <TableCell>
        </TableCell>
        <TableCell>
        </TableCell>
        <TableCell align="center">
        </TableCell>
        <TableCell>
        </TableCell>
    </TableRow>
};
import React, { useEffect } from 'react';
import Unity, { UnityContext } from "react-unity-webgl";
import './project.scss';
import { Modal, Spinner } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { Button } from '@material-ui/core';
import { NativeAppPopup } from './Components/native-app-popup';
import { Dispatch } from '../../components/dispatcher';
import Cookies from 'js-cookie';

export const ProjectPage = () => {

    const [playerSize, setPlayerSize] = useState();
    const [canDeeplink, setCanDeeplink] = useState(false);
    const [loadUnity, setLoadUnity] = useState(false);
    const [unityContext, setUnityContext] = useState(null);
    const [cacheId, setCacheId] = useState(null);

    const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

    useEffect(() => {
        window.addEventListener("resize", onWindowResize);
        onWindowResize();

        setLoadUnity(true);

        Dispatch("api/build/getbuildcacheid").then(cacheId => {
            setCacheId(cacheId);

            var unityContext = new UnityContext({
                loaderUrl: `/unitybuild/build/${cacheId}-Default WebGL.loader.js`,
                dataUrl: `/unitybuild/build/${cacheId}-Default WebGL.data.br`,
                frameworkUrl: `/unitybuild/build/${cacheId}-Default WebGL.framework.js.br`,
                codeUrl: `/unitybuild/build/${cacheId}-Default WebGL.wasm.br`
            });

            setUnityContext(unityContext);

            //2020
            //setUnityContext(new UnityContext({
            //    loaderUrl: `/unitybuild/build/${cacheId}-Default WebGL.loader.js`,
            //    dataUrl: `/unitybuild/build/${cacheId}-Default WebGL.data.br`,
            //    frameworkUrl: `/unitybuild/build/${cacheId}-Default WebGL.framework.js.br`,
            //    codeUrl: `/unitybuild/build/${cacheId}-Default WebGL.wasm.br`,
            //}));
        });

        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);

    const onWindowResize = () => {
        setPlayerSize({ width: window.innerWidth, height: window.innerHeight, position: 'fixed' });
    };

    return <div>
        <script src={`/unitybuild/build/${cacheId}-Default WebGL.loader.js`} />
        {/*<NavLink to="/" className="back-button">
            <Button color="primary" variant="contained"><span className="icon-arrow-left" /></Button>
        </NavLink>*/}
        <div className="project-loading-indicatior d-flex justify-content-center align-items-center">
            <h3>Loading...</h3>
        </div>
        {unityContext && loadUnity && <Unity style={{ ...playerSize, overflow: 'hidden' }} className="unity-player" unityContext={unityContext} />}
    </div>;
};
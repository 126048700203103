import React from 'react';
import {  TableRow, TableCell, IconButton } from '@material-ui/core';
import { DashCircleFill } from 'react-bootstrap-icons';

export const LodLevelComponent = (props) => {

    return <TableRow>
        {/* <TableCell className="text-center">
            {props.name}
        </TableCell> */}
        <TableCell className="text-center">
            {props.orderOfLoading}
        </TableCell>
        <TableCell className="text-center">
            {props.fileSize}
        </TableCell>
        <TableCell className="text-center">
            <IconButton color="secondary" onClick={() => props.onRemove()}>
                <DashCircleFill/>
             </IconButton>
        </TableCell>
    </TableRow>
};
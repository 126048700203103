import React, { useEffect, useState} from 'react';
import { Button, Toolbar, Paper, Drawer, IconButton, Typography, AccordionSummary, Accordion, AccordionDetails } from "@material-ui/core"
import './native-app-container.scss';
import { Card, Row, Col, Container, Modal, Spinner } from 'react-bootstrap';
import { Arrow90degDown, ArrowDown, ArrowDownShort, ArrowUpRightCircle, CloudDownloadFill, ExclamationCircle, Globe, QuestionCircle } from 'react-bootstrap-icons';
import { Dispatch } from '../../../components/dispatcher';
import moment from 'moment';
import { useParams } from 'react-router-dom';

const PlatformTypes = {
    Windows: 'Windows',
    IOS: 'iOS',
    Mac: 'Mac',
    Android: 'Android',
    Linux: 'Linux',
};

const PlatformEnum = {
    Windows: 0,
    Android: 20,
    IOS: 30,
    Windows: 40,
    Mac: 50
}

export const NativeAppPopup = (props) => {

    const deeplinkUrl = props.deeplinkUrl;
    const params = useParams();
    const [hide, setHide] = useState(false);
    const [myPlatform, setMyPlatform] = useState('');

    const [myPlatformBuild, setMyPlatformBuild] = useState(null);

    useEffect(() => {
        setMyPlatform(getPlatform());

    }, []);

    useEffect(() => {
        var myPlatformEnum = null;

        switch(myPlatform) {
            case PlatformTypes.Windows: 
                myPlatformEnum = PlatformEnum.Windows;
            break;
            case PlatformTypes.Mac: 
                myPlatformEnum = PlatformEnum.Mac;
            break;
        }

        getDownloadableClientProxy(myPlatformEnum).then(r => {
            setMyPlatformBuild(r);
        });
    } , [myPlatform]);
    

    const getDownloadableClientProxy = (platformEnum) => {
        return Dispatch(`api/build/GetLatestBuild/${platformEnum}`);
    };

    const onContinue = () => {
        props.onContinue();
        setHide(true);
    };

    const onDownloadCurrentClient = () => {
        window.open(myPlatformBuild.downloadURL);
    };

    const getPlatform = () => {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = PlatformTypes.Mac;
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = PlatformTypes.IOS;
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = PlatformTypes.Windows;
        } else if (/Android/.test(userAgent)) {
            os = PlatformTypes.Android;
        } else if (!os && /Linux/.test(platform)) {
            os = PlatformTypes.Linux;
        }

        return os;
    };

    if (hide) return null;

    return <Modal show size="xl" onHide={() => props.onClose()}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            {!myPlatformBuild && <Row className="justify-content-center">
                    <Col xs="auto"><Spinner animation="border" color="primary"></Spinner></Col>
                    <Col xs="auto">Launching your app ...</Col>
                </Row>}
            {myPlatformBuild && <>
                <Row className="my-2 justify-content-center">
                    <Col xs="auto">
                        <h3 className="text-center">We've detected you're on {myPlatform}. Download the client below.</h3>
                    </Col>
                </Row>
                <Row className="mb-2 justify-content-center">
                    <Col xs="auto">
                        <Button color="primary" variant="contained" className="mr-1" onClick={() => onDownloadCurrentClient()}>
                            <CloudDownloadFill className="mr-1" /> Download client now
                        </Button>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs="auto">
                        <Typography variant="caption">Client version last updated {moment(myPlatformBuild.finishedDateUTC).format("DD-MM-YYYY hh:mm")}</Typography>
                    </Col>
                </Row>
                <Row className="my-5">
                    <Col>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ArrowDownShort />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography ><QuestionCircle className="mr-2"/>Help</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ul>
                                    <li>Download the application from the link above.</li>
                                    <li>Run the app once to register it.</li>
                                    <li>You can open your projects by selecting them on the website.</li>
                                </ul>
                            </AccordionDetails>
                        </Accordion>   
                    </Col>
                </Row>
            </>}
            {/*!myPlatformBuild && <Row className="mb-2">
                <Col xs="auto">
                    Getting latest build for {myPlatform}.
                </Col>
                <Col xs="auto">
                    <Spinner animation="border"></Spinner>
                </Col>
            </Row>*/}
        </Modal.Body>
        {/*<Modal.Footer>
            <Row>
                <Col xs="auto">
                    <Button variant="outlined" className="w-100" onClick={() => onContinue()}>
                        <Globe className="mr-1" />
                        Continue
                    </Button>
                </Col>
                <Col xs="auto">
                    <Button href={deeplinkUrl} variant="contained" className="w-100" color="primary">
                        <ArrowUpRightCircle className="mr-1" />
                        Open in app
                    </Button>
                </Col>
            </Row>
        </Modal.Footer>*/}
    </Modal>;
};

window.bindWebGLTexture = (path, GL, FS) => {
    var stream = FS.open(path, 'r');
    var content = stream.object.contents;

    var image = document.createElement('image');
    image.src = window.URL.createObjectURL(stream);

    FS.close(stream);

    var texture = GL.texImage2D(GL.TEXTURE_2D, 0, GL.RGB, GL.RGB, GL.UNSIGNED_BYTE, image);
    return texture;
};
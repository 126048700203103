import { Typography } from '@material-ui/core';
import React from 'react';
import { Container, Alert } from 'react-bootstrap';

export const AdminHelpCreateModulePage = () => {
    return <Container className="mt-2 pb-2">

        <Typography variant="h4" className="my-4">What are modules?</Typography> 
        <Typography variant="subtitle1">
            Modules are the buildingbricks that define the Scanbie client. Each module captures a piece of functionality and encapsulates this.
            These modules are created in the Unity Game Engine and are managed by the server. 
            How they are loaded or who gets which right is completely managed in the web app. 
            <br/>
            <br/>
            All modules share a few standard specifications:
            <ul>
                <li><b>Id</b>: unique for each module</li>    
                <li><b>Name</b>: A human readable representation of the module.</li>    
                <li><b>Icon</b>: Unicode character that is injected in the Scanbie UI.</li>    
                <li><b>Right</b>: Read/Write, depends on how the module is defined for the current logged in user.</li>    
                <li><b>Is standard</b>: This includes the module in every project by default.</li>    
                <li><b>Is input</b>: A module can be responsible for capturing input or just be something additive.</li>    
            </ul> 
        </Typography> 

        <Typography variant="h4" className="my-4">Resources and locations</Typography> 

        <Typography variant="h6" className="my-2">Module root prefab</Typography> 
        <Typography variant="subtitle1">
            All modules are located in <b>Assets/Resources/ScanbieModules</b>. This is the location of the <b>root prefab containing the module script</b> that will be instantiated.
            When the application loads, it will search the modules here.
        </Typography> 
        <Typography variant="h4" className="my-4">Module resources</Typography> 
        <Typography variant="subtitle1">
            All resources (Scripts, prefabs, textures, etc) which are associated with these modules are found in <b>Assets/Modules</b>. 
            When creating new modules it is expected to create a new folder inside this path.
            <br/>
            It is <b>Very important</b> to ensure the right folder structure. Please create folders for Scripts, Prefabs, Textures, etc under the associated module folder.
            The <b>Annotation module</b> is an example you could follow.
        </Typography> 
        <Typography variant="h4" className="my-4">Code splitting</Typography> 
        <Typography variant="subtitle1">
            When creating modules, things might get complicated after a while. To ensure the best workflow and scalability we use the domain driven programming approach.
            This basicly means we try to split Logic and Domain data into 2 seperate classes: 
            <ul>
                <li><b>Domain code</b>: Classes defining the data structures, always end with -Data or -DTO</li>    
                <ul>
                    <li><b>Data classes</b>: Classes containing data. I.e.: a Person, a house, etc.</li>    
                    <li><b>DTO Classes</b>: DTO (Data transfer objects) are used to transform above -Data classes and send them to the server. They can also be received from the server and transformed back.</li>     
                </ul>
                <li><b>Logic code</b>: Classes that manipulate our data. I.e.: changing a persons name</li>     
                <li><b>UI code</b>: Classes that represent the data structures, These are usually root scripts of prefabs and inherit the Entity class.</li>     
            </ul>
            It is wise to use the <b>Annotation module</b> code as a guidance. This does not contain logic scripts because there is not really enough logic going on for it to be seperated.
        </Typography> 

        <Typography variant="h4" className="my-4">Authenticating</Typography> 
        
        <Typography variant="subtitle1">
            You have to be authenticated in the unity editor in order to get the correct projects/module rights. You can authenticate to the Scanbie server using the menu <b>Scanbie/Settings</b> in the unity editor.
        </Typography> 

        <Typography variant="h4" className="my-2">Creating a module</Typography> 

        <ul>
            <li>Create and go to the folder <b>Modules/YOUR_MODULE_NAME/Scripts/Domain</b> and create a script <b>"YOUR_MODULE_NAME"DTO</b> and make it inherit <b>ProjectModuleInstanceDto</b>.</li> 
            <ul>
                <li>This DTO script will contain the data the module uses and will be sent to the server for saving or loading.</li>    
            </ul>
            <li>Create and go to the folder <b>Modules/YOUR_MODULE_NAME/Scripts</b> and create a script with the name of the module, ending in 'module'. Make the script inherit <b>ScanbieModule</b>
                and provide the previously created DTO class as the generic parameter.
                </li>    
            <li>Create a prefab in the <b>Resources/ScanbieModules</b> where on the root gameobject the script of the module is attached. Fill in the module design data.</li> 
            <li>Go to the menu <b>Scanbie/Modules</b>. A list of modules will be shown. If all went well your new module should appear in the list and you can sync your module to the server. An admin account is required to perform this action.</li> 
        </ul>
        <Alert variant="info">
            Modules only have to be synced once. It is to let the platform know there are is a new module. When you change the code of the module, the scanbie client must be rebuild completely for changes to take effect on the WebGL or mobile version.
        </Alert>
        
        <Typography variant="h4" className="my-2">Modules API</Typography> 
        <Typography variant="subtitle1" className="my-2">When developing the modules, there is a lot of premade stuff ready to use. Every module has protected access to the gameContainer instance. This container gives access to the UI or APIService.</Typography> 
        
        <Typography variant="h5" className="my-2">UI</Typography> 

        <Typography variant="h6" className="my-2">Loading indicator</Typography> 
        <Typography variant="subtitle1" className="my-2">Can be used to show a loading indicator on screen.</Typography> 
        <iframe src="https://pastebin.com/embed_iframe/iDhz5M3j" style={{height: 120, border:'none',width:'100%'}}></iframe>


        <Typography variant="h6" className="my-2">Modal</Typography> 
        <Typography variant="subtitle1" className="my-2">Shows a popup modal with a title, message, onYesClicked, onNoClicked, yesText, noText</Typography> 
        <iframe src="https://pastebin.com/embed_iframe/EakJkLjG" style={{height: 80, border:'none',width:'100%'}}></iframe>


        <Typography variant="h6" className="my-2">Module bar</Typography> 
        <Typography variant="subtitle1" className="my-2">Used to add items to the module bar on the bottom right of the screen.</Typography> 
        <iframe src="https://pastebin.com/embed_iframe/tPBsecuv" style={{height: 200, border:'none',width:'100%'}}></iframe>

        <Typography variant="h6" className="my-2">Context menu</Typography> 
        <Typography variant="subtitle1" className="my-2">Used to add items to the context menu</Typography> 
        <iframe src="https://pastebin.com/embed_iframe/QRpfdFuD" style={{height: 500,border:'none',width:'100%'}}></iframe>

        <Typography variant="h5" className="my-2">Module</Typography>

        <Typography variant="h6" className="my-2">Loading data from the server</Typography> 
        <Typography variant="subtitle1" className="my-2">When overriding the following method, you get access to the module data saved on the server. This data is a list of instances.</Typography> 
        <iframe src="https://pastebin.com/embed_iframe/Yb8Pkci6"style={{height: 120,border:'none',width:'100%'}}></iframe>

        <Typography variant="h6" className="my-2">Saving modules instances</Typography> 
        <Typography variant="subtitle1" className="my-2">You can save your data as an instance in the server. When saved, the id the module has on the server is returned. You can pass in the id in the "null" parameter to update that entry instead of creating a new one.</Typography> 
        <iframe src="https://pastebin.com/embed_iframe/DHCM8Hqu" style={{height: 120,border:'none',width:'100%'}}></iframe>
        
        <Typography variant="h6" className="my-2">Refreshing modules</Typography> 
        <Typography variant="subtitle1" className="my-2">You can refresh your modules by calling this method. OnModuleDataReceived will be invoked later on.</Typography> 
        <iframe src="https://pastebin.com/embed_iframe/EhBbbRMX" style={{height: 120,border:'none',width:'100%'}}></iframe>
       
        <Typography variant="h4" className="my-2">Input</Typography> 
        <Typography variant="subtitle1" className="my-2">Always use the <b>gameContainer.InputManager</b> for input!</Typography> 
        
        <br/>
        <br/>
        <br/>
    </Container>;
};
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner, Form, Accordion, Modal } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import { Dispatch } from '../../components/dispatcher';
import { useToasts } from 'react-toast-notifications';
import { useModal } from '../../components/layout/modal';
import { UserModules } from './components/user-modules';
import { Box, PersonPlusFill } from 'react-bootstrap-icons';
import { UserRoles } from '../../constants';
import { InviteUser } from './components/invite-user';
import { Paper, Button, Checkbox, IconButton, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { UserInvitationComponent } from './components/user-invitation/user-invitation-component';
import { UserComponent } from './components/user-invitation/user-component';

export const ManageUsersPage = () => {

    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState("");
    const [showInviteUser, setShowInviteUser] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const { addToast } = useToasts();
    const { showYesNoModal } = useModal();

    useEffect(() => {
        reload();
    }, []);

    const reload = () => {
        Dispatch("api/manageusers/getusers").then(r => {
            setUsers(r);
        });
    };

    const deleteUser = (user) => {
        showYesNoModal("Warning", `Are you sure you want to delete ${user.name}?`, () => {
            Dispatch(`api/manageusers/delete/${user.id}`, 'DELETE').then(r => {
                addToast('User deleted!', { appearance: 'success' });
                reload();
            }).catch(e => {
                addToast("Error deleting user!", { appearance: 'error' });
            });
        });
    };

    const onChangeAdmin = (checked, userId) => {
        let userRoleId = checked ? UserRoles.Admin : UserRoles.User;

        Dispatch(`api/manageusers/setuserrole/${userId}/${userRoleId}`, 'POST').then(r => {
            addToast("User role changed!", { appearance: 'success' });
            reload();
        });
    };

    const onChangeCanCreateProjects = (checked, userId) => {

        Dispatch(`api/manageusers/setcancreateprojects/${userId}/${checked}`, 'POST').then(r => {
            addToast(checked ? "User can add projects!" : "User cannot add projects!", { appearance: 'success' });
            reload();
        });
    };

    if (users.length == 0) return <Spinner animation="spinner" />;

    return <Container>
        <Paper className="p-1">
            <Row className="justify-content-between align-items-center mb-1">
                <Col xs="auto">
                    <Row className="align-items-center">
                        <Col xs="auto pr-0">
                            <NavLink to="/">
                                <IconButton variant="light"><span className="icon-arrow-left" /></IconButton>
                            </NavLink>
                        </Col>
                        <Col>
                            <h3>MANAGE USERS</h3>
                        </Col>
                    </Row>
                </Col>
                <Col xs="auto">
                    <Button variant="contained" color="primary" onClick={() => setShowInviteUser(true)}><PersonPlusFill className="mr-1" /> Invite new client</Button>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col>
                    <p className="scanbie-title">Summary</p>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col xs={12}>
                    <Form.Control type="name" name="name" placeholder="Search" onChange={e => setSearch(e.target.value)} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Id</TableCell>
                                    <TableCell align="center">Name</TableCell>
                                    <TableCell align="center">Email</TableCell>
                                    <TableCell align="center">Admin</TableCell>
                                    <TableCell align="center">Can create projects</TableCell>
                                    <TableCell align="center">Modules</TableCell>
                                    <TableCell align="center">Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((u, i) => {
                                    let isInvitationPending = u.isInvitationPending;
                                    if (isInvitationPending) {
                                        return <UserInvitationComponent
                                            key={i}
                                            search={search}
                                            userData={u}
                                            onChangeAdmin={onChangeAdmin}
                                            onChangeCanCreateProjects={onChangeCanCreateProjects}
                                            setSelectedUser={setSelectedUser}
                                            deleteUser={deleteUser}
                                        ></UserInvitationComponent>
                                    } else {
                                        return <UserComponent
                                            key={i}
                                            search={search}
                                            userData={u}
                                            onChangeAdmin={onChangeAdmin}
                                            onChangeCanCreateProjects={onChangeCanCreateProjects}
                                            setSelectedUser={setSelectedUser}
                                            deleteUser={deleteUser}>
                                        </UserComponent>
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Col>
            </Row>
            {selectedUser && <Modal onHide={() => setSelectedUser(null)} show={selectedUser != null} onHide={() => { setSelectedUser(null); reload(); }} size="xl">
                <Modal.Header>
                    <span>Configure modules for user <b>{selectedUser.name}</b></span>
                </Modal.Header>
                <Modal.Body>
                    <UserModules userId={selectedUser.id} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" color="primary" onClick={() => setSelectedUser(null)}>Done</Button>
                </Modal.Footer>
            </Modal>}
            <InviteUser show={showInviteUser} onHide={() => setShowInviteUser(false)} />
        </Paper>
    </Container>;
};
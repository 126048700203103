import React, { useState, useEffect, useContext, createContext } from 'react';
import {
    Container, ProgressBar, Modal, Row, Col
} from 'react-bootstrap';
import { NavMenu } from './nav-menu';
import { ToastProvider } from 'react-toast-notifications';
import { SkeletonTheme } from "react-loading-skeleton";
import { InfoPanel } from './info-panel';
import { ModalProvider } from './layout/modal';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { CircularProgress, Drawer } from '@material-ui/core';
import './layout.scss';
import { useLocation, useHistory } from 'react-router-dom';
import { useConfig } from '../App';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#577b84",
        },
        secondary: {
            main: "#dc3131",
        },
    }
});

export let setLoadingIndicator, setShowLoadingProgress, setLoadingProgress;

export const Layout = (props) => {

    const { getUserProfile } = useConfig();

    const location = useLocation();
    const history = useHistory();

    const [loadingIndicatorState, setLoadingIndicatorState] = useState(false);
    const [infoPanelVisible, setInfoPanelVisible] = useState(true);
    const [headerVisible, setHeaderVisible] = useState(true);

    const [showLoadingProgressState, setShowLoadingProgressState] = useState(false);
    const [progressValue, setProgressValue] = useState("");

    const userProfile = getUserProfile();

    useEffect(() => {
        history.listen((newLocation) => {
            setPanels(newLocation.pathname);
        });

        setLoadingIndicator = (val) => setLoadingIndicatorState(val);
        setShowLoadingProgress = (val) => setShowLoadingProgressState(val);
        setLoadingProgress = (val) => setProgressValue(val);

        setPanels(location.pathname);
    }, []);

    const setPanels = (pathname) => {

        if (pathname === "/login") {
            setInfoPanelVisible(false);
            setHeaderVisible(false);
        } else if (pathname === "/register") {
            setInfoPanelVisible(false);
            setHeaderVisible(false);
        } else if (pathname === "/request-recover-password") {
            setInfoPanelVisible(false);
            setHeaderVisible(false);
        } else if (pathname.indexOf("/recover-password") >= 0) {
            setInfoPanelVisible(false);
            setHeaderVisible(true);
        } else if (pathname.indexOf("/project/") >= 0) {
            setInfoPanelVisible(false);
            setHeaderVisible(false);
        } else {
            setInfoPanelVisible(true);
            setHeaderVisible(true);
        }

        //login
        //request recover password
        //recover password -- enkel info panel
        //register
        //project
    };

    const setShowInfoPanel = (show) => {
        setInfoPanelVisible(show);
    };

    const setShowHeader = (show) => {
        setHeaderVisible(show);
    };

    return (
        <ThemeProvider theme={theme} >
            <ToastProvider placement="bottom-right" autoDismiss>
                <SkeletonTheme color="rgba(0, 0, 0, 0.3)" highlightColor="rgba(0, 0, 0, 0.1)">
                    <ModalProvider>
                        {loadingIndicatorState && <div className="d-flex justify-content-center align-items-center" style={{ zIndex: 9999999, position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }} color="primary">
                            <CircularProgress color="primary" />
                        </div>}
                        {showLoadingProgressState && <Modal backdrop="static" show={showLoadingProgressState}>
                            <Modal.Header className="text-center">
                                Uploading...
                                </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <ProgressBar now={progressValue} label={`${progressValue}%`} />
                                </div>
                            </Modal.Body>
                        </Modal>}
                        <div style={{ display: "flex" }}>
                            {infoPanelVisible && <Drawer className="info-panel" variant="permanent" anchor="left">
                                <InfoPanel />
                            </Drawer>}
                            <div className="page-content">
                                <Container fluid>
                                    {headerVisible && <Row>
                                        <Col className="mb-1">
                                            <NavMenu userProfile={userProfile} />
                                        </Col>
                                    </Row>}
                                    <Row className="mt-1">
                                        <Col>
                                            {props.children}
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </ModalProvider>
                </SkeletonTheme>
            </ToastProvider>
        </ThemeProvider>);
}
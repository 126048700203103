import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner, Form, Table } from 'react-bootstrap';
import { Dispatch } from '../../../components/dispatcher';
import { useToasts } from 'react-toast-notifications';
import { Module } from '../../../components/module/module';

export const UserModules = (props) => {

    const [userModules, setUserModules] = useState([]);

    const { addToast } = useToasts();

    useEffect(() => {
        reload();
    }, []);

    const reload = () => {
        Dispatch(`api/manageusers/getusermodules/${props.userId}`).then(r => {
            setUserModules(r);
        });
    };

    const onModuleChange = (checked, moduleId) => {
        if (checked) {
            Dispatch(`api/manageusers/addUserModule/${props.userId}/${moduleId}`, 'POST').then(r => {
                addToast("Module added!", { appearance: 'success' });
                reload();
            });
        }
        else {
            Dispatch(`api/manageusers/removeUserModule/${props.userId}/${moduleId}`, 'POST').then(r => {
                addToast("Module removed!", { appearance: 'success' });
                reload();
            });
        }
    };

    if (userModules.length == 0) {
        return <Row className="justify-content-center">
            <Col xs="auto">
                <Spinner animation="grow" />
            </Col>
        </Row>;
    }

    return <Row className="justify-content-start">
        {userModules.map((m, i) => <Col key={i} xs="auto" className="my-3">
            <Module
                key={i}
                name={m.name}
                isStandardModule={m.isStandard}
                canAddModule={!m.isStandard && !m.owned}
                canRemoveModule={!m.isStandard && m.owned}
                onAdd={() => onModuleChange(true, m.id)}
                onRemove={() => onModuleChange(false, m.id)}
            />
        </Col>)}
    </Row>;
};
import React from 'react';
import { Container, Form } from 'react-bootstrap';
import { Dispatch } from '../../components/dispatcher';
import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

import '../login/login-page.scss';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { useModal } from '../../components/layout/modal';

export const RecoverPasswordPage = () => {

    const [errorMessage, setErrorMessage] = useState('');
    const { showInfoModal } = useModal();
    const history = useHistory();
    const { token } = useParams();

    const submitLogin = (event) => {
        event.preventDefault();

        let form = event.currentTarget;

        if(form["password"].value != form["password-check"].value) 
        {
            setErrorMessage("Passwords do not match!");
            return;
        }

        Dispatch(`api/user/ResetPassword`, "POST", {token: token, password: form["password"].value}).then(r => {
            showInfoModal("Password has been changed!", "You can now log back in with your new credentials.", () => {
                history.push("/login");
            });
        });
    };

    return <Container className="full-page">
        <Row className="justify-content-center mt-5">
            <Col xs={12} md={7} lg={5}>
                <Form onSubmit={e => submitLogin(e)} className="login-form d-flex flex-column align-items-center justify-content-center py-4">
                    <img src="/images/logo.png" width="55%" alt="logo"/>
                    <Form.Group>
                        {errorMessage && <Alert variant="danger">
                            {errorMessage}
                        </Alert>}
                    </Form.Group>
                    <Form.Group className="text-center" controlId="password" required>
                        <Form.Label>New password</Form.Label>
                        <Form.Control type="password" />
                    </Form.Group>
                    <Form.Group className="text-center" controlId="password-check" required>
                        <Form.Label>Repeat password</Form.Label>
                        <Form.Control type="password" />
                    </Form.Group>
                    <Button type="submit"><span className="icon-rotate-3d"/> Recover password</Button>
                </Form>
            </Col>
        </Row>
        <img className="astro" alt="astro" src="/images/astro-body.png" />
    </Container>
};
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner, Form } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import { Dispatch } from '../../components/dispatcher';
import { useToasts } from 'react-toast-notifications';
import { useModal } from '../../components/layout/modal';
import { Paper, Button, IconButton, Checkbox, Table, TableHead, TableBody, TableRow, TableCell, TableContainer } from '@material-ui/core';


export const ManageModulesPage = () => {

    const [modules, setModules] = useState([]);
    const { addToast } = useToasts();
    const { showYesNoModal } = useModal();

    useEffect(() => {
        reload();
    }, []);

    const reload = () => {
        Dispatch("api/module/GetManageModuleData").then(r => {
            setModules(r);
        });
    };

    const deleteModule = (module) => {
        showYesNoModal("Warning", `Are you sure you want to delete ${module.name}?`, () => {
            Dispatch(`api/module/DeleteModule/${module.id}`, 'DELETE').then(r => {
                addToast('Saved successfully!', { appearance: 'success' });
                reload();
            }).catch(e => {
                addToast("Error deleting module!", { appearance: 'error' });
            });
        });
    };

    const saveModule = (module) => {
        Dispatch(`api/module/SaveModule`, 'POST', module).then(r => {
            addToast('Saved successfully!', { appearance: 'success' });
            reload();
        }).catch(e => {
            addToast("Error deleting module!", { appearance: 'error' });
        });
    };

    if (modules.length == 0) return <div className="justify-content-center p-4">
        No modules found, add them here.
    </div>;

    return <Container>
        <Paper className="p-1">
            <Row className="align-items-center">
                <Col xs="auto pr-0">
                    <NavLink to="/">
                        <IconButton variant="light"><span className="icon-arrow-left" /></IconButton>
                    </NavLink>
                </Col>
                <Col>
                    <h3>MANAGE MODULES</h3>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col>
                    <p className="scanbie-title">Summary</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Module</TableCell>
                                    <TableCell className="text-center">Default module</TableCell>
                                    <TableCell className="text-center">Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {modules.map((m, i) => <TableRow key={i}>
                                    <TableCell>
                                        {m.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Checkbox color="primary" defaultChecked={m.isStandardModule} onChange={(e) => { m.isStandardModule = e.target.checked; saveModule(m); }} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton color="secondary" onClick={() => deleteModule(m)}><span className="icon-delete" /></IconButton>
                                    </TableCell>
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Col>
            </Row>
        </Paper>
    </Container>;
};
import { setLoadingIndicator, setShowLoadingProgress, setLoadingProgress } from "./layout";
import * as axios from 'axios';

export const DispatchForm = (url, formData) => {
    return new Promise((resolve, reject) => {
        setLoadingProgress(0, "Preparing upload");
        setShowLoadingProgress(true);

        axios.request({
            method: "post",
            url: url,
            data: formData,
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setLoadingProgress(percentCompleted);
            }
        })
            .then(r => {
                setShowLoadingProgress(false);
                resolve(r.data);

            }).catch(e => {
                setShowLoadingProgress(false);
                reject(e.response.data);
            });
    });
}

export const Dispatch = (url, method, body, showLoadingIndicator = true) => {

    if (setLoadingIndicator && showLoadingIndicator) setLoadingIndicator(true);
    return new Promise((resolve, reject) => {
        let payload = {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;',
                //'X-Requested-With': 'XMLHttpRequest'
            }
        };

        if (method && method.toLowerCase() != "get") {
            payload.body = JSON.stringify(body);
        }

        fetch(url, payload)
            .then(r => {
                if (setLoadingIndicator && showLoadingIndicator) setLoadingIndicator(false);

                if (!r.ok) {
                    r.text().then((rText) => reject(rText));
                    return;
                }

                r.json().then(json => {
                    resolve(json);
                }).catch(() => {
                    resolve();
                });
        })
            .catch(e => {
                if (setLoadingIndicator && showLoadingIndicator) setLoadingIndicator(false);
                reject(e);
        });
    });
};

import React, { createContext } from 'react';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';


const ModalContext = createContext({
    showYesNoModal: (title, message, onYes, onNo = null) => { },
    showInfoModal: (title, message, onYes) => { }
});

export const useModal = () => {
    return useContext(ModalContext);
};

var onNoEvent = null;
var onYesEvent = null;

export const ModalProvider = (props) => {

    const [showYesNo, setShowYesNo] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    const showYesNoModal = (title, message, onYes, onNo = null) => {
        setShowYesNo(true);

        setTitle(title);
        setMessage(message);

        onYesEvent = onYes;
        onNoEvent = onNo;
    };

    const showInfoModal = (title, message, onYes) => {
        setShowInfo(true);

        setTitle(title);
        setMessage(message);
        
        onYesEvent = onYes;
    };

    return <ModalContext.Provider value={{ showYesNoModal, showInfoModal }}>
        <Modal show={showYesNo} onHide={() => setShowYesNo(false)}>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    setShowYesNo(false);
                    if (onNoEvent) onNoEvent();
                }}>
                    <span className="icon-close" /> Cancel
                </Button>
                <Button variant="primary" onClick={() => {

                    setShowYesNo(false);
                    if (onYesEvent) onYesEvent();
                }}>
                    <span className="icon-check" /> Yes
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showInfo} onHide={() => setShowInfo(false)}>
        <Modal.Header>{title}</Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => {
                    setShowInfo(false);
                    if (onYesEvent) onYesEvent();
                }}>
                    <span className="icon-check" /> Okay
                </Button>
            </Modal.Footer>
        </Modal>
        {props.children}
    </ModalContext.Provider>;
};
import JSZip from 'jszip';

const getObject = (key, success, error) => {
    let openRequest = indexedDB.open("/idbfs");

    openRequest.onerror = () => {
        error(openRequest.error);
    };

    openRequest.onsuccess = () => {
        let db = openRequest.result;

        let transaction = db.transaction(["FILE_DATA"], "readonly");
        let objStore = transaction.objectStore("FILE_DATA");

        let req = objStore.get(key);

        req.onsuccess = () => {
            success(req.result);
        };

        req.onerror = () => {
            error(key, req.error);
        };
    };
};

const updateObject = (key, obj, success, error) => {
    let openRequest = indexedDB.open("/idbfs");

    openRequest.onerror = () => {
        error(openRequest.error);
    };

    openRequest.onsuccess = () => {
        let db = openRequest.result;

        let transaction = db.transaction(["FILE_DATA"], "readwrite");
        let objStore = transaction.objectStore("FILE_DATA");

        let req = objStore.put(obj, key);

        req.onsuccess = () => {
            success();
        };

        req.onerror = () => {
            error(req.error);
        };
    };
};

window.unzip = (inputZipPath, outputDirectory, onSuccess, onError, errorSetup) => {
    if (inputZipPath.trim() == '') return;

    var zip = new JSZip();
    getObject(inputZipPath, (file) => {
        zip.loadAsync(file.contents).then(entry => {
            let keys = Object.keys(entry.files);

            keys.forEach(k => entry.file(k).async("uint8array").then(content => {
                const key = outputDirectory + "/" + k;
                getObject(key, (result) => {
                    result.contents = content;
                    updateObject(key, result, () => {
                        console.log(`${key} - ${result}`);
                        onSuccess(key);
                    }, (err) => {
                        onError(key, err);
                    });
                }, errorSetup);

            }));
        });
    }, errorSetup);
}

window.fetchProjectLod = (projectId, orderOfLoading, nextLodLevelNam, persistedDataPath, FS, onDone) => {
    fetch(`api/file/lod/${projectId}/${orderOfLoading}`).then(response => response.arrayBuffer()).then(arrayBuffer => {
        var zip = new JSZip();
        const dir = persistedDataPath + "/" + projectId.toString() + "/" + nextLodLevelNam;
        if (FS.analyzePath(dir).exists) {
            FS.rmdir(dir, { recursive: true });
        }
        FS.mkdir(dir);
        zip.loadAsync(arrayBuffer).then(entry => {
            let keys = Object.keys(entry.files);
            let index = 0;
            keys.forEach(k => {
                index++;
                let file = entry.file(k);
                if (file != null) {
                    file.async("uint8array").then(content => {
                        FS.writeFile(dir + "/" + k, content);
                        if (index >= keys.length) {
                            FS.syncfs(false,
                                (err) => {
                                    onDone();
                                });
                        }
                    });
                }
            });
        });
    });
};
import React from 'react';
import { Row, Nav, Navbar } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const InfoPanel = () => {

    const goToScanbieWebsite = () => {
        window.open("https://www.scanbie.com", "_blank");
    };

    return <>
        <div className="h-100 d-flex flex-column justify-content-between align-items-center p-3">
            <div className="d-flex flex-column justify-content-center align-items-center">
                <img onClick={() => goToScanbieWebsite()} className="img-fluid my-3" style={{ cursor: "pointer" }} alt="scanbie-www" src="/images/www-scanbie-com.png" />
                <div className="scanbie-logo" onClick={() => window.location.href = "/"} style={{ position: 'relative' }}>
                    {/* <img className="info-panel-drone drone-1" src="/images/drone.png" />
                    <img className="info-panel-drone drone-2" src="/images/drone.png" /> */}
                </div>
                <img width="70%" className="img-fluid" style={{cursor: "pointer"}} alt="scanbie-logo" src="/images/logo.png" />
            </div>
            <div>
                <Row className="justify-content-center">
                    <Col xs="auto"><Link to="">Help</Link></Col>
                    <Col xs="auto"><Link to="">Privacy Policy</Link></Col>
                    <Col xs="auto"><Link to="">Terms of service</Link></Col>
                </Row>
            </div>
        </div>
    </>;
};
import React from 'react';
import { Paper, Button, Checkbox, IconButton, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { UserRoles } from '../../../../constants';
import { Col, Row } from 'react-bootstrap';
import { Box } from 'react-bootstrap-icons';

export const UserComponent = (props) => {
    const userData = props.userData;
    const search = props.search;

    const isScanbieAdmin = userData.userRoleId == UserRoles.Admin;

    return <TableRow hidden={!(!search || userData.name?.toLowerCase().indexOf(search.toLowerCase()) >= 0)}>
        <TableCell>
            {userData.id}
        </TableCell>
        <TableCell>
            <Row className="align-items-center justify-content-start">
                <Col xs="auto">
                    <div style={{ backgroundImage: `url("/api/user/GetProfilePicture/${userData.id}?size=64"), url("/images/astro-64.png")` }} className="profile-image" />
                </Col>
                <Col xs="auto">
                    {userData.name} {userData.isMe ? " (You)" : ""}
                </Col>
            </Row>
        </TableCell>
        <TableCell>
            {userData.email}
        </TableCell>
        <TableCell>
            <Checkbox color="primary" checked={isScanbieAdmin} onChange={(e) => props.onChangeAdmin(e.target.checked, userData.id)} />
        </TableCell>
        <TableCell>
            <Checkbox color="primary" checked={(userData.canCreateProjects || isScanbieAdmin)} disabled={isScanbieAdmin} onChange={(e) => props.onChangeCanCreateProjects(e.target.checked, userData.id)} />
        </TableCell>
        <TableCell align="center">
            {!isScanbieAdmin && <IconButton variant="contained" onClick={() => props.setSelectedUser(userData)}>
                <Box />
            </IconButton>}
        </TableCell>
        <TableCell>
            {!userData.isMe && <IconButton color="secondary" variant="contained" onClick={() => props.deleteUser(userData)}>
                <span className="icon-delete" />
            </IconButton>}
        </TableCell>
    </TableRow>
};
import React from 'react';
import { Row, Form, Container } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { useState } from 'react';
import { Collaborators } from '../../components/collaborators/collaborators';
import { DispatchForm } from '../../components/dispatcher';
import { useModal } from '../../components/layout/modal';
import { useHistory, NavLink } from 'react-router-dom';
import { Paper, Button, IconButton } from '@material-ui/core';

export const CreateProjectPage = () => {

    const [projectIcon, setProjectIcon] = useState(null);
    const { showInfoModal } = useModal();
    const history = useHistory();

    const onProjectIconChanged = (e) => {
        let input = e.target;

        if (input.files.length != 1) return;

        setProjectIcon(window.URL.createObjectURL(input.files[0]));
    };

    const onSubmit = (e) => {
        e.preventDefault();

        let form = e.currentTarget;

        form.checkValidity();

        let formData = new FormData();
        formData.append("iconFile", form["icon-file"].files[0]);
        formData.append("projectName", form["project-name"].value);
        formData.append("addressLine1", form["address-line-1"].value);
        formData.append("city", form["city"].value);
        formData.append("state", form["state"].value);
        formData.append("zip", form["zip"].value);
        formData.append("country", form["country"].value);
        formData.append("contactName", form["contact-name"].value);
        formData.append("contactTel", form["contact-telephone"].value);
        formData.append("contactEmail", form["contact-email"].value);

        DispatchForm("api/projects/createproject", formData).then(r => {
            showInfoModal("Project created!", "Project created successfully, you will be redirected to the project page where you can start inviting other collaborators.", () => {
                history.push(`project-settings/${r.id}`)
            });
        }).catch(e => {
            showInfoModal("Error", e.toString());
        });
    };

    return <Container>
        <Paper className="p-1 pb-3">
            <Form onSubmit={e => onSubmit(e)}>
                <Row className="align-items-center mb-1">
                    <Col xs="auto">
                        <NavLink to="/">
                            <IconButton variant="light"><span className="icon-arrow-left" /></IconButton>
                        </NavLink>
                    </Col>
                    <Col>
                        <h3>CREATE YOUR PROJECT</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="scanbie-title">Summary</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <h4>Project info</h4>
                        <Form.Group controlId="icon-file" required>
                            <Form.Label>
                                Upload an icon
                            </Form.Label>
                            <img className="my-2" style={{ display: "block", width: 150, height: 150, margin: 'auto', objectFit: "cover" }} src={projectIcon || `/images/logo.png`} />
                            <Form.Control type="file" accept="image/x-png,image/gif,image/jpeg" required onChange={(e) => onProjectIconChanged(e)} />
                        </Form.Group>
                        <Form.Group controlId="project-name">
                            <Form.Label>Project Name</Form.Label>
                            <Form.Control type="text" required />
                        </Form.Group>
                        <Form.Group controlId="address-line-1">
                            <Form.Label>Address line 1</Form.Label>
                            <Form.Control type="text" required />
                        </Form.Group>
                        <Row>
                            <Col md={12} lg={6}>
                                <Form.Group controlId="city">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control type="text" required />
                                </Form.Group>
                            </Col>
                            <Col md={12} lg={6}>
                                <Form.Group controlId="state">
                                    <Form.Label>State / Province</Form.Label>
                                    <Form.Control type="text" required />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} lg={6}>
                                <Form.Group controlId="zip">
                                    <Form.Label>Zip code</Form.Label>
                                    <Form.Control type="text" required />
                                </Form.Group>
                            </Col>
                            <Col md={12} lg={6}>
                                <Form.Group controlId="country">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control type="text" required />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={6}>
                        <h4>Contact info</h4>
                        <Form.Group controlId="contact-name">
                            <Form.Label>Contact name</Form.Label>
                            <Form.Control type="text" required />
                        </Form.Group>
                        <Form.Group controlId="contact-telephone">
                            <Form.Label>Contact tel.</Form.Label>
                            <Form.Control type="tel" required />
                        </Form.Group>
                        <Form.Group controlId="contact-email">
                            <Form.Label>Contact email</Form.Label>
                            <Form.Control type="email" required />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="justify-content-end">
                    <Col xs="auto">
                        <Button variant="contained" color="primary" type="submit"><span className="icon-rocket" /> Create project</Button>
                    </Col>
                </Row>
            </Form>
        </Paper>
    </Container>
};
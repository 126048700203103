import React from 'react';
import { Row, Col, Form, Spinner } from 'react-bootstrap';
import { CloudUpload, PlusCircle, PlusCircleFill } from 'react-bootstrap-icons';
import { Card, CardHeader, CardActions, Button, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, IconButton } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { Dispatch, DispatchForm } from '../../../../components/dispatcher';
import { setLoadingProgress, setShowLoadingProgress, useLayout } from '../../../../components/layout';
import { AssetTypes, HugeUploadAssetType } from '../../../../constants';
import * as TUS from 'tus-js-client';
import { useToasts } from 'react-toast-notifications';
import { LodLevelComponent } from './components/lod-level-component';
import { useModal } from '../../../../components/layout/modal';

export const ProjectFiles = (props) => {

    const params = useParams();
    const { addToast } = useToasts();
    const { showYesNoModal } = useModal();

    const uploadProjectFile = (assetType, files) => {

        if (files.length == 0) return;
        let file = files[0];

        setShowLoadingProgress(true);
        setLoadingProgress(0, "Preparing upload");

        var upload = new TUS.Upload(file, {
            endpoint: '/file/uploadLarge',
            metadata: {
                projectId: params.id,
                assetType: assetType
            },
            retryDelays: [0, 3000, 5000, 10000, 20000],
            onError: (error) => {
                addToast(`Error file upload: ${error}`, { appearance: 'error' });
                setShowLoadingProgress(false);
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
                setLoadingProgress(percentage, "Preparing upload");
            },
            onSuccess: () => {
                console.log("Download %s from %s", upload.file.name, upload.url)
                setShowLoadingProgress(false);
                addToast(`Successfully uploaded file!`, { appearance: 'success' });
                props.onReload();
            }
        });

        upload.start();
    };

    const deleteLOD = (orderOfLoading) => {
        showYesNoModal("Warning!", "Are you sure you want to delete this LOD?", () => {
            Dispatch(`api/projects/deleteLOD/${params.id}/${orderOfLoading}`, "DELETE").then(r => {
                addToast(`Deleted LOD successfully!`, { appearance: 'success' });
                props.onReload();
            });
        });
    };
    const deleteTexturePack = (orderOfLoading) => {
        showYesNoModal("Warning!", "Are you sure you want to delete this texture pack?", () => {
            Dispatch(`api/projects/deleteTexturePack/${params.id}/${orderOfLoading}`, "DELETE").then(r => {
                addToast(`Deleted LOD successfully!`, { appearance: 'success' });
                props.onReload();
            });
        });
    };


    const bytesToSize = (bytes) => {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    };

    const hasLodLevels = props.lodLevels.length > 0;
    const hasTexturePacks = props.textureLevels.length > 0;

    return <>
        {hasLodLevels == 0 && <Row className="justify-content-center align-items-center m-5">
            <Col xs="auto">
                No LOD files found. Upload your first GLB file.
            </Col>
        </Row>}
        {hasLodLevels && <Row>
            <Col xs={12}>
                <TableContainer component={Paper}>
                    <Table >
                        <TableBody>
                            <TableRow >
                                {/* <TableCell component="th" align="center">
                                    Hash
                                </TableCell> */}
                                <TableCell component="th" align="center">
                                    Order of loading
                                    </TableCell>
                                <TableCell component="th" align="center">
                                    Download size
                                    </TableCell>
                                <TableCell component="th" align="center">
                                </TableCell>
                            </TableRow>
                            {props.lodLevels.map((l, i) => <LodLevelComponent
                                key={i}
                                orderOfLoading={l.orderOfLoading}
                                fileSize={bytesToSize(l.fileSize)}
                                uncompressedFileSize={bytesToSize(l.uncompressedFileSize)}
                                name={l.name}
                                onRemove={() => deleteLOD(l.orderOfLoading)}
                            />)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Col>
        </Row>}
        <Row className="justify-content-end align-items-center my-3">
            <Col xs="auto">
                <Button color="primary" variant="contained" onClick={() => document.getElementById(`lod-file`).click()}>
                    <PlusCircle className="mr-2" /> Upload new LOD
                </Button>
            </Col>
        </Row>
        <Form.File hidden id="lod-file" accept=".glb" onChange={(e) => uploadProjectFile(HugeUploadAssetType.LOD, e.target.files)} />

        {/*!hasTexturePacks && <Row className="justify-content-center align-items-center m-5">
            <Col xs="auto">
                No Texture packs found. Upload a ZIP file containing the textures references by the .mtl files.
            </Col>
        </Row>*/}
        {/*hasTexturePacks && <Row>
            <Col xs={12}>
                <TableContainer component={Paper}>
                    <Table >
                        <TableBody>
                            <TableRow >
                                <TableCell component="th" align="center">
                                    Hash
                                </TableCell>
                                <TableCell component="th" align="center">
                                    Order of loading
                                    </TableCell>
                                <TableCell component="th" align="center">
                                    Download size
                                    </TableCell>
                                <TableCell component="th" align="center">
                                </TableCell>
                            </TableRow>
                            {props.textureLevels.map((l, i) => <LodLevelComponent
                                key={i}
                                orderOfLoading={l.orderOfLoading}
                                fileSize={bytesToSize(l.fileSize)}
                                name={l.name}
                                onRemove={() => deleteTexturePack(l.orderOfLoading)}
                            />)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Col>
        </Row>*/}
        {/*<Row className="justify-content-end align-items-center my-3">
            <Col xs="auto">
                <Button color="primary" variant="contained" onClick={() => document.getElementById(`texture-file`).click()}>
                    <PlusCircle className="mr-2" /> Upload new texture pack
                </Button>
            </Col>
        </Row>
        <Form.File hidden id="texture-file" accept=".zip" onChange={(e) => uploadProjectFile(HugeUploadAssetType.TexturePack, e.target.files)} />
        */}
    </>;
};
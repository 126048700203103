import React, { useEffect } from "react";
import { Tooltip, OverlayTrigger, Badge, Button, Col, Row } from "react-bootstrap";
import { Plus, Dash, Trash, PlusCircle, TrashFill, PlusCircleFill, EyeSlash, Eye, Pencil } from "react-bootstrap-icons";
import { Card, CardContent, CardHeader, CardActions, CardMedia, Paper } from '@material-ui/core';
import { useState } from "react";


export const ProjectUserModule = (props) => {

    const [roleId, setRoleId] = useState(0);

    useEffect(() => {
        setRoleId(props.roleId);
    }, [props.roleId, props.isAdmin]);

    const getModuleRoleText = () => {
        if (roleId == 3) return "Cannot use";
        if (roleId == 2) return "Can view";
        if (roleId == 1) return "Can edit";
    };

    const onChange = (selectedRoleId) => {
        setRoleId(selectedRoleId);
        props.onChange(selectedRoleId);
    };

    return <Card className="card-project-user-module" style={{ opacity: props.isStandardModule ? 0.5 : 1 }}>
        <CardMedia image="images/cube.svg"></CardMedia>
        <CardHeader title={props.name}>
        </CardHeader>
        <CardContent>
            {getModuleRoleText()}
        </CardContent>
        <CardActions disableSpacing>
            <div className="module-roles ">
                <Paper>
                    <Row className="flex-column">
                        <Col xs="auto">
                            <Button disabled={props.isAdmin} variant={!props.isAdmin && roleId == 3 ? 'primary' : 'outlined'} onClick={() => onChange(3)}>
                                <EyeSlash />
                            </ Button>
                        </Col>
                        <Col xs="auto">
                            <Button disabled={props.isAdmin} variant={!props.isAdmin && roleId == 2 ? 'primary' : 'outlined'} onClick={() => onChange(2)}>
                                <Eye />
                            </ Button>
                        </Col>
                        <Col xs="auto">
                            <Button disabled={props.isAdmin} variant={props.isAdmin || roleId == 1 ? 'primary' : 'outlined'} onClick={() => onChange(1)}>
                                <Pencil />
                            </ Button>
                        </Col>
                    </Row>
                </Paper>
            </div>
        </CardActions>
    </Card>;
};
import React from 'react';
import { Modal, ListGroupItem, Col, Button, DropdownButton, Tooltip, OverlayTrigger, Form, Table, Alert, Collapse, Card, Accordion, Spinner, Badge } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
import { FormControl } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Plus, XCircle, QuestionCircle, PlusCircle, X, EnvelopeFill, TrashFill, EyeFill, Pencil, Eye, EyeSlash, Exclamation, ExclamationTriangle } from 'react-bootstrap-icons';
import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useEffect } from 'react';
import { Dispatch } from '../dispatcher';
import { useModal } from '../layout/modal';

export const CollaboratorsInvite = (props) => {
    const [projectId, setProjectId] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const [invitationEmail, setInvitationEmail] = useState('');
    const [invitationProjectRoleId, setInvitationProjectRoleId] = useState(2);

    const { showInfoModal } = useModal();

    const [invitations, setInvitations] = useState([]);
    const [modules, setModules] = useState([]);

        useEffect(() => {
        if (!props.projectId) {
            setIsLoading(false);
            return;
        }

        setInvitationEmail('');
        setProjectId(props.projectId);
        setInvitationProjectRoleId(2);
        setIsLoading(true);
        setInvitations([]);

        Dispatch(`api/projects/GetInvitationData/${props.projectId}`).then(r => {
            setModules(r.modules.map(m => { return { moduleId: m.id, name: m.name, roleId: 2 }; }));
            setIsLoading(false);
        });

    }, [props.projectId, props.show]);

    const addInvitation = () => {
        let newInvitations = [...invitations];
        newInvitations.push({ email: invitationEmail, modules: modules.map(m => { return { ...m }; }) });

        setInvitationEmail('');
        setInvitations(newInvitations);
    };

    const removeInvitation = (index) => {
        let newInvitations = [...invitations];
        newInvitations.splice(index, 1);

        setInvitations(newInvitations);
    };

    const setModuleRole = (moduleId, roleId) => {
        let newModules = [...modules];
        newModules[newModules.findIndex(m => m.moduleId == moduleId)].roleId = roleId;
        setModules(newModules);
    };

    const setAllModuleRole = (roleId) => {
        let newModules = [...modules];
        newModules.forEach(m => m.roleId = roleId);
        setModules(newModules);
    };

    const isAllModuleRole = (roleId) => {
        return modules.every(m => m.roleId == roleId);
    };

    const getModuleRoleText = (roleId) => {
        if (roleId == 3) return "Cannot use";
        if (roleId == 2) return "Can view";
        if (roleId == 1) return "Can edit";
    };

    const requestCancel = () => {
        props.onHide();
    };

    const sendInvitations = () => {
        for (let i = 0; i < invitations.length; i++) {
            Dispatch("api/projects/Invite", "POST", {
                projectId: projectId,
                invitationEmail: invitations[i].email,
                projectRoleId: parseInt(invitationProjectRoleId),
                invitationModuleRoles: invitations[i].modules
            }).then(r => {
                if (r && r.error) {
                    let newInvitations = [...invitations];
                    newInvitations[i].error = r.error;
                    setInvitations(newInvitations);
                }
                checkForErrorsAndComplete();
            }).catch(e => {
                showInfoModal("Error", e);
            });
        }
    };

    const checkForErrorsAndComplete = () => {
        if (invitations.every(i => !i.error)) {
            props.onHide();
        }
    };

    if (isLoading) {
        return <Spinner animation="grow" />;
    }

    return <Modal show={props.show} size="xl" backdrop="static">
        <Modal.Body>
            <Row className="justify-content-between mb-2">
                <Col xs={12} md={6}>
                    <Row>
                        <Col xs={12}>
                            <h4>Invite clients</h4>
                        </Col>
                        <Col xs={12}>
                            <Form>
                                <Form.Group controlId="email" required>
                                    <Form.Label>Client email</Form.Label>
                                    <Form.Control type="email" name="email" placeholder="Client email" value={invitationEmail} onChange={e => setInvitationEmail(e.target.value)} />
                                </Form.Group>
                                <Form.Group controlId="role" required>
                                    <Form.Label>
                                        Project role {' '}
                                        <OverlayTrigger overlay={<Tooltip>Administrators have an additional right to invite other people to your project. Clients can only interact with modules.</Tooltip>}>
                                            <QuestionCircle />
                                        </OverlayTrigger>
                                    </Form.Label>
                                    <Form.Control as="select" onChange={(e) => setInvitationProjectRoleId(e.target.value)}>
                                        <option value="2" id="2">Administrator</option>
                                        <option value="3" id="3">Client</option>
                                    </Form.Control>
                                </Form.Group>
                                <Collapse in={!!invitationEmail && invitationProjectRoleId == 3}>
                                    <div>
                                        <Form.Label>Module roles {' '}
                                            <OverlayTrigger overlay={<Tooltip>Assign roles for each module in this project.</Tooltip>}>
                                                <QuestionCircle />
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Card className="mb-1">
                                            <Card.Body>
                                                {modules.length == 0 && <p className="mb-0">You have no modules assigned to you in this project.</p>}
                                                {modules.length > 0 && <Table className="mb-0">
                                                    <tbody style={{ display: /*'block'*/ '', width: '100%', maxHeight: 200, overflowY: 'auto' }}>
                                                        <tr>
                                                            <td>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex flex-row justify-content-end align-items-center">
                                                                    <Button variant={isAllModuleRole(3) ? 'primary' : 'outlined'} onClick={() => setAllModuleRole(3)}>
                                                                        <EyeSlash />
                                                                    </ Button>
                                                                    <Button variant={isAllModuleRole(2) ? 'primary' : 'outlined'} onClick={() => setAllModuleRole(2)}>
                                                                        <Eye />
                                                                    </ Button>
                                                                    <Button variant={isAllModuleRole(1) ? 'primary' : 'outlined'} onClick={() => setAllModuleRole(1)}>
                                                                        <Pencil />
                                                                    </ Button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        {modules.map((m, i) => {
                                                            return <tr key={i}>
                                                                <td>
                                                                    <p className="mb-1">{m.name}</p>
                                                                    <span className="font-weight-light">{getModuleRoleText(m.roleId)}</span>
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex flex-row justify-content-end align-items-center">
                                                                        <Button variant={m.roleId == 3 ? 'primary' : 'outlined'} onClick={() => setModuleRole(m.moduleId, 3)}>
                                                                            <EyeSlash />
                                                                        </ Button>
                                                                        <Button variant={m.roleId == 2 ? 'primary' : 'outlined'} onClick={() => setModuleRole(m.moduleId, 2)}>
                                                                            <Eye />
                                                                        </ Button>
                                                                        <Button variant={m.roleId == 1 ? 'primary' : 'outlined'} onClick={() => setModuleRole(m.moduleId, 1)}>
                                                                            <Pencil />
                                                                        </ Button>
                                                                    </div>
                                                                </td>
                                                            </tr>;
                                                        })}
                                                    </tbody>
                                                </Table>}
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Collapse>
                                <Button className="w-100 mb-2" onClick={() => addInvitation()} disabled={!invitationEmail}><PlusCircle /> Add</Button>
                            </Form>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={6}>
                    <Row>
                        <Col>
                            <h4>Ready for invite</h4>
                        </Col>
                    </Row>
                    {invitations.length == 0 && <Row>
                        <Col>
                            <Alert variant="info">No invitations added yet.</Alert>
                        </Col>
                    </Row>}
                    {invitations.length > 0 && <Row>
                        <Col>
                            <ListGroup>
                                {invitations.map((inv, i) => <ListGroupItem key={i}>
                                    <Row className="d-flex flex-row justify-content-between align-items-center">
                                        <Col xs="auto">
                                            {inv.email}
                                        </Col>
                                        <Col xs="auto">
                                            <Button variant="light" onClick={() => removeInvitation(i)}>
                                                <span className="icon-delete" />
                                            </Button>
                                        </Col>
                                    </Row>
                                    {inv.error && <Row className="mb-2">
                                        <Col>
                                            <Alert variant="danger">
                                                <ExclamationTriangle />
                                                {" " + inv.error}
                                            </Alert>
                                        </Col>
                                    </Row>}
                                    <Row>
                                        <Col>
                                            <Accordion>
                                                <Accordion.Toggle eventKey="0">
                                                    Toon modules
                                                    </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <ListGroup variant="flush">
                                                        {inv.modules.map((module, i2) => <ListGroupItem className="p-0 py-1 d-flex flex-row align-items-center" key={i2}>
                                                            <p className="font-weight-light m-0 mr-1">{module.name}</p>
                                                            {module.roleId == 3 && <EyeSlash />}
                                                            {module.roleId == 2 && <Eye />}
                                                            {module.roleId == 1 && <Pencil />}
                                                        </ListGroupItem>)}
                                                    </ListGroup>
                                                </Accordion.Collapse>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </ListGroupItem>)}
                            </ListGroup>
                        </Col>
                    </Row>}
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
            <Button variant="light" onClick={() => requestCancel()}><X />Cancel</Button>
            <Button onClick={() => sendInvitations()} disabled={invitations.length == 0}><EnvelopeFill /> Send</Button>
        </Modal.Footer>
    </Modal>;
};
import * as React from 'react';
import { Navbar, Nav, NavDropdown, Form, Jumbotron, Badge, Modal, Alert, Row, Col, OverlayTrigger, Tooltip, DropdownButton, Dropdown, Container } from 'react-bootstrap';
import { Dispatch } from './dispatcher';
import { useEffect } from 'react';
import { useState } from 'react';
import { Plus, Box, Person, Hammer, PersonFill, BarChart, Newspaper, Shop, CartFill, HouseFill, DoorClosed, DoorOpenFill, QuestionCircleFill, ShieldLockFill, House } from 'react-bootstrap-icons';
import { Popover, List, ListItem, ListItemIcon, ListItemText, AppBar, Toolbar, IconButton, MenuItem, Menu, Button, Drawer, SwipeableDrawer, Paper, Divider } from '@material-ui/core';
import { useHistory, NavLink } from 'react-router-dom';
import { useRef } from 'react';
import { List as ListIcon } from 'react-bootstrap-icons';
import { useLayout } from './layout';
import { useConfig } from '../App';

export const NavMenu = (props) => {

    const [userProfile, setUserProfile] = useState(props.userProfile);
    const [hasOverflow, setHasOverflow] = useState(false);

    const [adminDropdownElem, setAdminDropdownElem] = useState(null);
    const [helpDropdownElem, setHelpDropdownElem] = useState(null);
    
    const [loginAnchorEl, setLoginAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const history = useHistory();
    const overflowRef = useRef();
    const { reloadUserProfile } = useConfig();

    let collapseValue = 0;

    useEffect(() => {
        onResize();
    }, [userProfile]);

    useEffect(() => {
        setUserProfile(props.userProfile);
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [props.userProfile]);

    const onResize = () => {
        let currentElementWidth = overflowRef.current.scrollWidth;
        let overflow = overflowRef.current && currentElementWidth > window.innerWidth;

        let hasOverflow = overflow;

        if (hasOverflow) {
            collapseValue = currentElementWidth;
            setHasOverflow(true);
        } else {
            if (currentElementWidth > collapseValue) {
                setHasOverflow(false);
            }
        }
    };

    const logOut = () => {
        Dispatch("api/user/logout", "POST").then(r => {
            reloadUserProfile(() => {
                history.push('/');
            });
        });
    }

    const profileRenderer = () => {
        return <Badge className="d-flex flex-row align-items-center">
            <span className="mr-1">{userProfile?.userName}</span>
            <div className="profile-image" style={{ backgroundImage: `url("/api/user/GetProfilePicture"), url("/images/astro.png")` }} />
        </Badge>;
    };

    let loggedIn = (userProfile && userProfile.userId > 0);
    let isScanbieAdmin = userProfile?.role == 1;

    const adminDropdownListItemsRenderer = () => {
        const goTo = (location) => {
            history.push(location);
            setAdminDropdownElem(null);
            setDrawerOpen(false);
        };

        return <>
            <ListItem button onClick={() => goTo('/manage-modules')}><ListItemIcon><Box /></ListItemIcon><ListItemText>Manage modules</ListItemText></ListItem>
            <ListItem button onClick={() => goTo('/manage-users')}><ListItemIcon><PersonFill /></ListItemIcon><ListItemText>Manage users</ListItemText></ListItem>
            <ListItem button onClick={() => goTo('/manage-builds')}><ListItemIcon><Hammer /></ListItemIcon><ListItemText>Build history</ListItemText></ListItem>
            <ListItem button disabled><ListItemIcon><BarChart /></ListItemIcon><ListItemText>Analytics</ListItemText></ListItem>
            <ListItem button disabled><ListItemIcon><Newspaper /></ListItemIcon><ListItemText>Updates</ListItemText></ListItem>
        </>;
    };

    const helpDropdownListItemsRenderer = () => {
        const goTo = (location) => {
            history.push(location);
            setHelpDropdownElem(null);
            setDrawerOpen(false);
        };

        return <>
             {isScanbieAdmin && <ListItem button onClick={() => goTo('/admin-help-create-module')}><ListItemIcon><Box /></ListItemIcon><ListItemText>Creating modules</ListItemText></ListItem>}
            {/* {isScanbieAdmin && <ListItem button onClick={() => goTo('/admin-help-deploying-build')}><ListItemIcon><Hammer /></ListItemIcon><ListItemText>Deploying builds</ListItemText></ListItem>}  */}
            <ListItem button onClick={() => goTo('/help-create-glb')}><ListItemIcon><House /></ListItemIcon><ListItemText>Creating .GLB files</ListItemText></ListItem>
        </>;
    };

    return <Container>
        <Toolbar className="nav-menu p-0">
            <div className="w-100">
                <Row className="justify-content-between align-items-center" ref={overflowRef}>
                    <Col>
                        <Row>
                            {!hasOverflow && <Col xs="auto">
                                <Button color="primary" className="navbar-item" onClick={() => history.push('/')}>
                                    <HouseFill className="mr-1" /> Home
                                </Button>
                                {loggedIn && <Button color="primary" disabled className="navbar-item" ><CartFill className="mr-1" /> Shop</Button>}
                                {isScanbieAdmin && <>
                                    <Button color="primary" className="navbar-item" onClick={(e) => setAdminDropdownElem(e.currentTarget)}><ShieldLockFill className="mr-1" /> Admin </Button>
                                    <Popover
                                        open={!!adminDropdownElem}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        onClose={() => setAdminDropdownElem(null)}
                                        anchorEl={adminDropdownElem}
                                    >
                                        <List>
                                            {adminDropdownListItemsRenderer()}
                                        </List>
                                    </Popover>
                                </>}
                                <>
                                <Button color="primary" className="navbar-item" onClick={(e) => setHelpDropdownElem(e.currentTarget)}><QuestionCircleFill className="mr-1" /> Help </Button>
                                <Popover
                                    open={!!helpDropdownElem}
                                    anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    onClose={() => setHelpDropdownElem(null)}
                                    anchorEl={helpDropdownElem}
                                >
                                    <List>
                                        {helpDropdownListItemsRenderer()}
                                    </List>
                                </Popover>
                                </>
                            </Col>}
                            {hasOverflow && <IconButton className="ml-1" onClick={(e) => setDrawerOpen(true)}>
                                <ListIcon />
                            </IconButton>}
                        </Row>
                    </Col>
                    <Col xs="auto">
                        {loggedIn && <Button
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={(e) => setLoginAnchorEl(e.currentTarget)}
                        >
                            <span className="mr-1">{userProfile.userName}</span>
                            <div style={{ backgroundImage: `url("/api/user/GetProfilePicture/${userProfile.userId}?size=64"), url("/images/astro-64.png")` }} className="profile-image" />
                        </Button>}
                        {!loggedIn && <Button
                            color="primary"
                            onClick={() => history.push('login')}
                            className="navbar-item"
                        >
                            <DoorOpenFill className="mr-1" /> Log in
                    </Button>}
                    </Col>
                </Row>
                <Popover
                    open={!!loginAnchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={() => setLoginAnchorEl(null)}
                    anchorEl={loginAnchorEl}
                >
                    <List>
                        <ListItem button component="a" onClick={() => { history.push('/profile'); setLoginAnchorEl(null); }}><ListItemIcon><span className="icon-account" /></ListItemIcon><ListItemText>Profile</ListItemText></ListItem>
                        <ListItem button component="a" onClick={() => { logOut(); setLoginAnchorEl(null); }}><ListItemIcon><span className="icon-walk" /></ListItemIcon><ListItemText>Log out</ListItemText></ListItem>
                    </List>
                </Popover>
            </div>
        </Toolbar>
        <SwipeableDrawer
            anchor="bottom"
            open={drawerOpen}
            onOpen={(e) => setDrawerOpen(true)}
            onClose={() => setDrawerOpen(false)}>
            <List>
                <ListItem button component="a" onClick={() => history.push('/')}><ListItemIcon><HouseFill /></ListItemIcon><ListItemText>Home</ListItemText></ListItem>
                {loggedIn && <ListItem button disabled component="a"><ListItemIcon><CartFill /></ListItemIcon><ListItemText>Shop</ListItemText></ListItem>}
                {isScanbieAdmin && <>
                    <Divider />
                    <ListItem><ListItemText primary="Admin tools" /></ListItem>
                    <Divider />
                    {adminDropdownListItemsRenderer()}
                </>}
                <>
                    <Divider />
                    <ListItem><ListItemText primary="Help" /></ListItem>
                    <Divider />
                    {helpDropdownListItemsRenderer()}
                </>
            </List>
        </SwipeableDrawer>
    </Container>;
}
import { Typography } from '@material-ui/core';
import React from 'react';
import { Container, Alert } from 'react-bootstrap';
import Image from 'material-ui-image';

export const HelpCreateGLBPage = () => {
    return <Container className="mt-2 pb-2">
        <Typography variant="h4" className="my-5">Exporting .GLB files</Typography> 

        <Typography variant="subtitle1">
           The Scanbie Platform uses .GLB files for transfering the 3D models. These models are known for their lightweightness and fast loading times. 
        </Typography> 

        <Alert variant="info">
            By default the newest version of blender will come with a GLTF/GLB exporter. When exporting you can choose GLTF or GLB. GLTF will export the textures, mesh and other binary data seperated into multiple files.
            GLB will take all of these files together and compress them very efficiently! This is why we only support GLB for now.
        </Alert>

        <Typography variant="h5" className="my-2">1. Open your model in blender.</Typography> 

        <Typography variant="subtitle1">
           When Blender has started, go to <b>File/Import/</b> and choose the format of the file you want to import. 
        </Typography> 

        <Typography variant="h5" className="my-2">2. Fixing missing textures</Typography> 
        <Typography variant="subtitle1">
           When the model is imported, you should check whether the textures are linked or not. This can be done by clicking on viewport shading:
           <Image src="/images/help/viewport-shading.jpg" aspectRatio={ 16 / 9}/>
           By default this is solid mode, which may not show the textures.
        </Typography> 
        <br/>
        <Typography variant="subtitle1">
           If no textures are found, you can try to tell Blender where to look for them. This is done via <b>File/External Data/Find missing files</b> and point to the folder containing the textures.
        </Typography> 
        <br/>
        <Typography variant="subtitle1">
           If that didn't work, you could manually link them. See below for further details.
        </Typography> 

        <Typography variant="h5" className="my-2">3. Defining the materials (and linking textures)</Typography> 
        <Alert variant="info">
            Models scanned by Scanbie already have lighting baked into them. This means we need to define the materials as unlit.
        </Alert>
        <Typography variant="subtitle1">
           1. In Blender, go to the Shading tab:
           <div className="my-2">
                <Image src="/images/help/shading-tab.jpg" aspectRatio={ 3 / 1}/>
           </div>
           2. You can select/switch materials via any of the location marked here:
           <div className="my-2">
                <Image src="/images/help/blender-material-locations.jpg" aspectRatio={ 2 / 1}/>
           </div>
           3. When you select your material you can edit the nodes defining it. What we want is to directly input the texture to the material output source:
           <div className="my-2">
                <Image src="/images/help/blender-material-node-link.jpg" aspectRatio={ 2 / 1}/>
           </div>
           4. If no texture input is available, you can add one using the <b>Add/Texture/Image texture</b> menu from add button shown on the image above. Make sure to link it to the output surface.
        </Typography> 

        <Typography variant="h5" className="my-2">4. Exporting the model to GLB</Typography> 
        <Typography variant="subtitle1" className="mb-2">
           When all materials and textures are set up, we can go and export the model. Go to <b>File/Export/GLTF 2.0</b> 
           <br/>
           It is very important to check if the following is configured correctly:
           <ul>
                <li><b>Format</b>: gLTF Binary (.glb)</li>    
                <li><b>Images</b>: jpg</li>    
                <li><b>Compression</b>: Turned ON and set to maximum (6)</li>    
            </ul> 
           <div className="my-2">
                <Image src="/images/help/blender-export-glb.jpg" aspectRatio={ 2 / 1}/>
           </div>
           Done! You can now upload the .glb file into the platform!
        </Typography>
    </Container>;
};
import React from "react";
import { Container, Row, Col, Form, Alert, Button } from "react-bootstrap";
import { Dispatch } from "../../components/dispatcher";
import { useState } from "react";
import { useLayout } from "../../components/layout";
import { useModal } from "../../components/layout/modal";
import { useParams } from "react-router-dom";


export const RegisterPage = () => {

    const [profileImage, setProfileImage] = useState(null);

    const { showInfoModal } = useModal();

    const submitRegister = (event) => {
        event.preventDefault();
        let form = event.currentTarget;

        if(form["password"].value != form["password-check"].value)
        {
            showInfoModal("Error", "Passwords do not match.");
            return;
        }

        Dispatch("api/user/register", "POST", {
            name: form["name"].value,
            email: form["email"].value,
            password: form["password"].value
        }).then(r => {
            if (r && r.error) {
                showInfoModal("Error", r.error);
            }
            else {
                if(form["icon-file"].files.length > 0)
                {
                    let formData = new FormData();
                    formData.append("profile-picture", form["icon-file"].files[0]);
    
                    fetch("api/user/UploadProfilePicture", {
                        method: 'POST',
                        body: formData
                    }).then(r => {
                        showInfoModal("Registered!", "Your account has been created and access has been granted to your projects!", () => {
                            window.location.href = "/";
                        });
                    });
                }
                else
                {
                    showInfoModal("Registered!", "Your account has been created and access has been granted to your projects!", () => {
                        window.location.href = "/";
                    });
                }
            }
        }).catch(e => {
            showInfoModal("Error", e);
        });
    };

    const onProfileImageSelected = (e) => {
        let input = e.target;

        if (input.files.length != 1) return;
        setProfileImage(window.URL.createObjectURL(input.files[0]));
    };
    
    return <Container className="full-page">
        <Row className="justify-content-center mt-5">
            <Col xs={12} md={7} lg={5}>
                <Form onSubmit={e => submitRegister(e)} className="login-form d-flex flex-column align-items-center justify-content-center py-4">
                    <h3>
                        Register account
                    </h3>
                    <Form.Group controlId="icon-file" required className="text-center" style={{color: 'white'}}>
                        <Form.Label>
                            Upload a profile picture
                        </Form.Label>
                        <img className="profile-image" style={{display: "block", margin: 'auto', width: 150, height: 150, objectFit: "cover" }} src={profileImage || `/images/astro.png`} />
                        <Form.Control type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e) => onProfileImageSelected(e)} />
                    </Form.Group>
                    <Form.Group className="text-center" controlId="name" required>
                        <Form.Label>Name *</Form.Label>
                        <Form.Control type="text" placeholder="Name"  />
                    </Form.Group>
                    <Form.Group className="text-center" controlId="email" required>
                        <Form.Label>Email address *</Form.Label>
                        <Form.Control type="email" placeholder="Email" />
                    </Form.Group>
                    <Form.Group className="text-center" controlId="password" required>
                        <Form.Label>Password *</Form.Label>
                        <Form.Control type="password" placeholder="Password"  />
                    </Form.Group>
                    <Form.Group className="text-center" controlId="password-check" required>
                        <Form.Label>Repeat Password *</Form.Label>
                        <Form.Control type="password" placeholder="Repeat password" />
                    </Form.Group>
                    <Button type="submit"><span className="icon-account"/> Register </Button>
                </Form>
            </Col>
        </Row>
        <img className="astro" alt="astro" src="/images/astro-body.png" />
    </Container>
};
import React, { useEffect, useState } from 'react';
import { Paper, Button, IconButton, Checkbox, Table, TableHead, TableBody, TableRow, TableCell, TableContainer } from '@material-ui/core';
import * as moment from 'moment';
import { Dispatch } from '../../components/dispatcher';
import { Row, Col, Spinner, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { CheckCircleFill, CloudDownloadFill, CloudDownload } from 'react-bootstrap-icons';

export const ManageBuildPage = () => {
    
    const [builds, setBuilds] = useState([]);

    const { addToast } = useToasts();

    useEffect(() => {
        reload();
    }, []);

    const reload = () => {
        Dispatch("api/build/gethistory").then(r => {
            setBuilds(r);
        });
    };

    const setAsCurrentBuild = (id, platform) => {
        Dispatch(`api/build/SetAsDefault/${id}/${platform}`, "POST").then(r => {
            addToast("Build successfully deployed.", { appearance: 'success' });
            reload();
        }).catch(e => {
            addToast("Error settings build.", { appearance: 'error' });
        });
    };

    if (builds.length == 0) return <Spinner animation="spinner" />;

    return <Container>
        <Paper className="p-1">
            <Row className="align-items-center">
                <Col xs="auto pr-0">
                    <NavLink to="/">
                        <IconButton variant="light"><span className="icon-arrow-left" /></IconButton>
                    </NavLink>
                </Col>
                <Col>
                    <h3>MANAGE UNITY BUILD</h3>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col>
                    <p className="scanbie-title">Summary</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Build</TableCell>
                                    <TableCell className="text-center">Date</TableCell>
                                    <TableCell className="text-center">Default</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {builds.map((b, i) => <TableRow key={i} selected={b.active}>
                                    <TableCell align="center">
                                        {b.active && <IconButton disabled>
                                            <CheckCircleFill />
                                        </IconButton>}
                                    </TableCell>
                                    <TableCell>
                                        #{b.id} {b.platformName}
                                    </TableCell>
                                    <TableCell align="center">
                                        {moment.utc(b.finishedDateUTC).local().format('DD-MM-YYYY hh:mm:ss')}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button variant="contained" disabled={b.active} color="primary" onClick={() => setAsCurrentBuild(b.id, b.platform)}><CloudDownload className="mr-1"/> Copy build to server</Button>
                                    </TableCell>
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Col>
            </Row>
        </Paper>
    </Container>;
};